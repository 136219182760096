import { UserCompanies } from "../../components/companies";
import { PlatformHeader } from "./header";
import PlatformColumns from "./platformPoints";

const Platform = () => (
  <div>
    <PlatformHeader />
    <UserCompanies />
    <PlatformColumns />
  </div>
);

export default Platform;
