import axios from "axios";

axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.response?.data?.detail || error?.message || error;
    }
    return Promise.reject(message);
  }
);

export class APIClient {
  controller;

  get = (url, params, headers = {}) => {
    this.controller = new AbortController();
    return axios.get(url, { params, signal: this.controller.signal, headers });
  };

  post = (url, data, headers = {}) => {
    this.controller = new AbortController();
    return axios.post(url, data, { signal: this.controller.signal, headers });
  };

  postWithParams = (url, params, data, headers = {}) => {
    this.controller = new AbortController();
    return axios.post(url, data, {
      params,
      signal: this.controller.signal,
      headers,
    });
  };

  update = (url, data, headers = {}) => {
    this.controller = new AbortController();
    return axios.patch(url, data, { signal: this.controller.signal, headers });
  };

  put = (url, data, headers = {}) => {
    this.controller = new AbortController();
    return axios.put(url, data, { signal: this.controller.signal, headers });
  };

  delete = (url, config = {}, headers = {}) => {
    this.controller = new AbortController();
    return axios.delete(url, {
      ...config,
      signal: this.controller.signal,
      headers,
    });
  };

  cancel = () => {
    this.controller.abort();
  };
}
