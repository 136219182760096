import React from "react";
import GetStarted from "../../components/getStarted";
import CustomHeader from "../../components/customHeader";
import Tutorials from "../../components/tutorials";

const Videos = () => {
  return (
    <div>
      <CustomHeader
        title={"Explore popular use cases to accelerate your development"}
        heading={"Tutorial"}
      />
      <Tutorials />
      <GetStarted />
    </div>
  );
};

export default Videos;
