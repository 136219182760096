import { useEffect } from "react";

const Blogs = () => {
  useEffect(() => {
    window.location.assign("https://blog.altimate.ai/");
  }, []);

  return null;
};

export default Blogs;
