import React, { useState, useEffect, useRef } from "react";
import altimateLogo from "../../assets/images/svgs/altimateLogoTransparent.svg";

const AltimateLogoComponent = () => {
  const [logoText1, setLogoText1] = useState("Alti");
  const [logoText2, setLogoText2] = useState(" AI");
  const [isFading, setIsFading] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setLogoText1(logoText1 === "Alti" ? "Data" : "Alti");
        setLogoText2(logoText2 === " AI" ? "s" : " AI");
        setIsFading(false);
      }, 500);
    }, 1500);
    return () => clearInterval(interval);
  }, [logoText1, logoText2]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className="flex flex-row gap-0 items-center justify-start">
      <img
        ref={imageRef}
        src={altimateLogo}
        alt="Altimate"
        onLoad={handleImageLoad}
      />
      {isImageLoaded && (
        <div className="w-[140px] text-center">
          <div className="text-2xl font-semibold text-[#355177]">
            <span
              className={`transition-opacity duration-500 ${
                isFading ? "opacity-25" : "opacity-100"
              }`}
            >
              {logoText1}
            </span>
            <span
              className={`transition-all duration-500 ${
                isFading ? "scale-105 font-semibold" : "scale-100"
              }`}
            >
              mate
            </span>
            <span
              className={`transition-opacity duration-500 ${
                isFading ? "opacity-25" : "opacity-100"
              }`}
            >
              {logoText2}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AltimateLogoComponent;
