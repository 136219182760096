import React from "react";

function FormSpreeForm({ setVisibleForm }) {
  const closeForm = () => {
    setVisibleForm(false);
  };
  return (
    <>
      <div
        onClick={closeForm}
        className="bg-black bg-opacity-75 fixed top-0 left-0 w-full h-full z-[10000]"
      ></div>
      <div className="bg-[#082247] form-popup">
        <iframe
          src="https://us10.list-manage.com/contact-form?u=e3e045441ad183e6171588539&form_id=c37bac2ee632d01dde7dd4c417922981"
          className="w-full h-[80vh]"
          title="Signup form"
        />
      </div>
    </>
  );
}

export default FormSpreeForm;
