import { useState } from "react";
import arrow from "../../assets/images/svgs/faq_arrow.svg";

const FAQDropdown = ({ question, answer, plainText = true }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div>
      <div
        onClick={handleClick}
        className={`${
          isOpen ? "bg-[#F5F7F9] rounded-b-[0px] border-[1px] border-b-0" : ""
        } transition-all flex justify-between items-center p-4 md:px-[30px] md:py-[27px] cursor-pointer rounded-[10px] border-[2px] border-[#8390A3] border-opacity-30`}
      >
        <div className={`text-left font-[500] text-[20px] text-[#082247]}`}>
          {question}
        </div>

        <img className={`${isOpen ? "rotate-180" : ""}`} src={arrow} alt="" />
      </div>

      {isOpen &&
        (plainText ? (
          <div
            dangerouslySetInnerHTML={{ __html: answer }}
            className="p-4 md:px-[30px] md:py-[20px] text-left font-normal text-[#365178] text-[16px] rounded-b-[10px]  border-[2px] border-t-0 border-[#8390A3] border-opacity-30"
          />
        ) : (
          <div className="p-4 md:px-[30px] md:py-[20px] text-left font-normal text-[#365178] text-[16px] rounded-b-[10px]  border-[2px] border-t-0 border-[#8390A3] border-opacity-30">
            {answer}
          </div>
        ))}
    </div>
  );
};

export default FAQDropdown;
