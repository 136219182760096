import CustomHeader from "../../components/customHeader";
import { SupportCards } from "./supportCards";

const Support = () => (
  <div style={{ background: "white" }}>
    <CustomHeader
      title="Get in touch with us for issues or feature requests"
      heading="Support"
    />
    <div className="flex flex-col items-center justify-center pb-0 md:pb-[120px]">
      <SupportCards />
    </div>
  </div>
);

export default Support;
