import React from "react";

import team1 from "../../assets/images/jpgs/team1.jpg";
import team2 from "../../assets/images/jpgs/team2.jpg";
import team3 from "../../assets/images/jpgs/team3.jpg";
import team4 from "../../assets/images/jpgs/team4.jpg";
import team5 from "../../assets/images/jpgs/team5.jpg";
import team6 from "../../assets/images/jpgs/team6.jpg";
import team7 from "../../assets/images/jpgs/team7.jpg";
import team8 from "../../assets/images/jpgs/team8.jpg";
import team9 from "../../assets/images/jpgs/team9.jpg";
import team10 from "../../assets/images/jpgs/team10.jpg";
import team11 from "../../assets/images/pngs/team11.png";
import team12 from "../../assets/images/pngs/team12.png";

import { FaLinkedinIn } from "react-icons/fa";

const Team = () => {
  const teamData = [
    {
      id: 1,
      img: team1,
      name: "Pradnesh Patil",
      designation: "Co-founder & CEO",
      linkedin: "https://www.linkedin.com/in/pradneshpatil/",
    },
    {
      id: 2,
      img: team2,
      name: "Anand Gupta",
      designation: "Co-founder & CTO",
      linkedin: "https://www.linkedin.com/in/anandguptas/",
    },
    {
      id: 3,
      img: team9,
      name: "Michiel De Smet",
      designation: "Founding Engineer",
      linkedin: "https://www.linkedin.com/in/michieldesmet/",
    },
    {
      id: 4,
      img: team3,
      name: "Surya Iyer",
      designation: "Founding Software Eng",
      linkedin: "https://www.linkedin.com/in/suryaiyer/",
    },
    {
      id: 5,
      img: team10,
      name: "John Ryan",
      designation: "Consultant",
      linkedin: "https://www.linkedin.com/in/johnryanuk/",
    },
    {
      id: 6,
      img: team12,
      name: "Rakend Dubba",
      designation: "Senior ML Engineer",
      linkedin: "https://www.linkedin.com/in/rakendd/",
    },
    {
      id: 7,
      img: team4,
      name: "Aditya Gajbhiye",
      designation: "Founding Software Eng",
      linkedin: "https://www.linkedin.com/in/aditya-gajbhiye-7b5b2a151/",
    },
    {
      id: 8,
      img: team7,
      name: "Md Sakib",
      designation: "Founding Product Designer",
      linkedin: "https://www.linkedin.com/in/sakibiz/",
    },
    {
      id: 9,
      img: team11,
      name: "Sarav Shanmugam",
      designation: "Senior Software Eng",
      linkedin: "https://www.linkedin.com/in/saravmajestic/",
    },
    {
      id: 10,
      img: team5,
      name: "Pulkit Gaur",
      designation: "Founding Software Eng",
      linkedin: "https://www.linkedin.com/in/gaurpulkit/",
    },
    {
      id: 11,
      img: team6,
      name: "Vipul Rao",
      designation: "Software Eng",
      linkedin: "https://www.linkedin.com/in/vipul-rao-974043165/",
    },
  ];
  return (
    <div className="team md:py-[100px] py-[50px]">
      <div className="custom-container">
        <div className="text-center">
          <p className="font-[600] text-xl text-[#247EFE]">Team</p>

          <h2 className="text-[#082247] md:text-[42px] text-[24px] font-semibold lh-2 mt-[10px]">
            Data veterans from <br />
            Fortune 100
          </h2>
        </div>

        <div className="mt-[40px] grid lg:grid-cols-4 md:grid-cols-2 gap-[30px]">
          {teamData.map((team, index) => (
            <div key={index} className="team-card">
              <div className="team-img">
                <img src={team.img} className="w-full" alt="" />
              </div>
              <div className="team-header flex justify-between items-center mt-[24px]">
                <h3 className="text-xl font-[600] text-[#082247]">
                  {team.name}
                </h3>
                <a
                  href={team.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex w-[24px] h-[24px] text-xs justify-center items-center bg-[#247EFE] rounded-full text-white"
                >
                  <FaLinkedinIn />
                </a>
              </div>

              <div className="team-designation mt-2 flex justify-start items-center">
                <div className="line mr-3 w-[20px] h-[2px] bg-[#8390A3]"></div>
                <p className="text-[#8390A3] text-sm">{team.designation}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
