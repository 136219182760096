import { APIClient } from "./apiHelper";
import { dbtProductName, vsCodeMarketplaceURL } from "./utils";

const apiClient = new APIClient();

export const vsCodeDBTDownloads = async () => {
  const requestBody = {
    filters: [
      {
        criteria: [
          {
            filterType: 7,
            value: dbtProductName,
          },
        ],
      },
    ],
    flags: 914,
  };
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json;api-version=3.0-preview.1",
  };

  return apiClient
    .post(vsCodeMarketplaceURL, requestBody, headers)
    .catch((error) => {
      console.error(error);
    });
};
