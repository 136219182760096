import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const ProductTextContent = ({ title, content, rev, workflowLink }) => (
  <div
    id={workflowLink}
    className={`product-workflow ${
      rev ? "product-workflow-reverse" : "product-workflow-straight"
    }`}
  >
    <div
      className={`product-text-content ${
        rev && "product-text-content-reverse"
      }`}
    >
      <div className="font-semibold text-[#247EFE] pb-[16px] md:pb-[20px]">
        {title}
      </div>
      <div className="text-base md:text-2xl mb-[24px]">{content.text}</div>
      <div className="md:block hidden">
        <Link to={content.button.link} replace={true}>
          <button className=" hover:bg-[#3677D9] bg-[#3F8CFF] px-[30px] py-[12px] text-white rounded-[12px] text-[16px] font-medium">
            {content.button.text}
          </button>
        </Link>
      </div>
    </div>
    <div
      className={`flex ${
        rev ? "flex-row-reverse" : "flex-row"
      } md:h-auto h-[244px] w-[370px] justify-center md:w-[44%] rounded-[12px] md:justify-start`}
    >
      <img
        className="md:h-auto h-[244px] md:w-full md:rounded-[12px]"
        src={content.gif}
        alt={content.text}
      />
    </div>
    <div className="md:hidden visible">
      <Link to={content.button.link} replace={true}>
        <button className="hover:bg-[#3677D9] bg-[#3F8CFF] px-[30px] py-[12px] text-white rounded-[12px] text-[16px] font-medium">
          {content.button.text}
        </button>
      </Link>
    </div>
  </div>
);

const ProductWorkflow = ({ workflowData }) => {
  const workflowLinking = useRef();
  useEffect(() => {
    workflowLinking.current = new MutationObserver(() => {
      const hash = window.location.hash.substring(1);
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView();
        workflowLinking.current.disconnect();
      }
    });
    workflowLinking.current.observe(document, {
      childList: true,
      subtree: true,
    });
    return () => {
      workflowLinking.current.disconnect();
    };
  }, []);

  return (
    <div className="product-workflows md:px-0 px-2">
      {workflowData.map((workflow, i) => (
        <ProductTextContent key={i} {...workflow} />
      ))}
    </div>
  );
};

export default ProductWorkflow;
