import { useState } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import newsletterImg from "../../assets/images/svgs/newsletter.svg";
import newsletterSuccessImg from "../../assets/images/svgs/newsletter_success.svg";
import { setEmailContact, validateEmail } from "./utils";

const ModalPopUp = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailAdded, setEmailAdded] = useState(0);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const addEmail = () => {
    setEmailAdded(1);
    setEmailContact(email, "website_vscode")
      .then(() => {
        setEmailAdded(2);
        setTimeout(() => {
          setModalIsOpen(false);
        }, 3000);
      })
      .catch((error) => {
        setEmailAdded(3);
      });
  };

  return (
    <Rodal
      visible={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      showCloseButton={true}
      closeOnEsc={true}
    >
      <div className="modal-content">
        <div className="input-content">
          <div className="heading">
            {emailAdded === 2 ? "Thank you" : "Newsletter"}
          </div>
          <div className="input-text">
            {emailAdded === 2
              ? "Thank you for subscribing to our newsletter. You should receive a confirmation email. Please check your inbox for regular updates."
              : "Sign up for our newsletter to get latest info on features and releases"}
          </div>
          {emailAdded !== 2 && (
            <div className="email-input-container">
              <input
                name="EMAIL"
                className="email-input focus:outline-none"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
              />
              <button
                disabled={emailError || !email}
                className={`email-input-button ${
                  emailError || !email ? "disabled" : ""
                }`}
                onClick={addEmail}
              >
                Join
              </button>
            </div>
          )}
          {emailAdded === 2 && (
            <button
              className="back-to-sign-up-button"
              onClick={() => setModalIsOpen(false)}
            >
              Back to Home Page
            </button>
          )}
          {emailAdded === 1 && <div className="input-text">Adding...</div>}
          {emailAdded === 3 && (
            <div className="input-text error">
              Error while adding the email, please try again
            </div>
          )}
        </div>
        <img
          className="modal-image"
          src={emailAdded === 2 ? newsletterSuccessImg : newsletterImg}
          alt="News Letter"
        ></img>
      </div>
    </Rodal>
  );
};

export default ModalPopUp;
