import React from "react";
import robot from "../../assets/images/svgs/robot1.svg";
import { Link } from "react-router-dom";

const BrokenPage = () => {
  return (
    <div className="min-h-screen broken flex flex-col justify-center items-center">
      <img src={robot} className="w-2/12 animated-robot" alt="robot" />

      <div className="mt-8 text-center">
        <h1 className="text-[#082247] font-bold text-[34px] mb-5">
          Oops! Something went wrong.
        </h1>

        <p className="text-[#082247] text-[18px] mb-5">
          We are working on fixing this issue. Please try again later.
        </p>

        <Link to={'/'} className="bg-[#247EFE] text-white px-8 py-3 rounded-full inline-block mt-2">
          Go Back
        </Link>
      </div>
    </div>
  );
};

export default BrokenPage;
