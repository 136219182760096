import analyticsEngineerDemo from "../../assets/images/gifs/home_gifs/analytics_engr.gif";
import platformEngineerDemo from "../../assets/images/gifs/home_gifs/platform_engr.gif";
import dataEngineerDemo from "../../assets/images/gifs/home_gifs/data_engr.gif";

import dataEngineer from "../../assets/images/svgs/home/dataPic.svg";
import analyticsEngineer from "../../assets/images/svgs/home/analyticsPic.svg";
import platformEngineer from "../../assets/images/svgs/home/platformPic.svg";
import { DBT_NAME } from "../../constant";

const engineerTypeSVGMap = {
  Analytics: analyticsEngineer,
  Platform: platformEngineer,
  Data: dataEngineer,
};

const EngineersHeader = ({ engineerType }) => (
  <div class="h-[84px] justify-start md:items-center items-start gap-4 inline-flex">
    <img src={engineerTypeSVGMap[engineerType]} alt={engineerType} />
    <div class="flex-col justify-center items-start inline-flex">
      <div class="text-[#082247] text-xl font-normal font-['Lexend'] leading-loose">
        Teammates for
      </div>
      <div class="text-[#247efe] md:text-[40px] text-2xl font-semibold font-['Lexend'] leading-[52px]">
        {engineerType} Engineers
      </div>
    </div>
  </div>
);

export const workflowData = [
  {
    title: <EngineersHeader engineerType="Analytics" />,
    workflowLink: "accelerate-with-ai",
    rev: false,
    content: {
      text: (
        <div class="text-[#082247] md:text-3xl text-xl font-medium font-['Lexend'] leading-[39px] md:px-0 px-6">
          Generate {DBT_NAME} models, tests, and documentation
        </div>
      ),
      gif: analyticsEngineerDemo,
      button: {
        text: "Learn More",
        link: "/analytics",
      },
    },
  },
  {
    title: <EngineersHeader engineerType="Platform" />,
    workflowLink: "prevent-issues",
    rev: true,
    content: {
      text: (
        <div class="text-[#082247] md:text-3xl text-xl font-medium font-['Lexend'] leading-[39px] md:px-0 px-6">
          Autotune warehouse configs 24/7 and recommend optimized queries
        </div>
      ),
      gif: platformEngineerDemo,
      button: {
        text: "Learn More",
        link: "/platform",
      },
    },
  },
  {
    title: <EngineersHeader engineerType="Data" />,
    workflowLink: "best-practices",
    rev: false,
    content: {
      text: (
        <div className="text-[#082247] md:text-3xl text-xl font-medium font-['Lexend'] leading-[39px] break-words text-wrap">
          Make workload migrations and data/query troubleshooting faster
        </div>
      ),
      gif: dataEngineerDemo,
      button: {
        text: "Start for free",
        link: "https://github.com/AltimateAI/datapilot-cli",
      },
    },
  },
];
