import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/styles/global.css";
import { RouterProvider } from "react-router-dom";
import router from "./router/main.router";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router}></RouterProvider>
  </QueryClientProvider>
);
