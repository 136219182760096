import Carousel from "nuka-carousel";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import bgSilq from "../../assets/images/pngs/silq_bg.png";
import bgHeyDay from "../../assets/images/pngs/heyday_bg.png";
import bgWiley from "../../assets/images/pngs/wiley_bg.png";
import iconSilq from "../../assets/images/svgs/silq_icon.svg";
import iconHeyDay from "../../assets/images/pngs/heyday_icon.png";
import userSilq from "../../assets/images/pngs/silq_vishnu.png";
import userHeyDay from "../../assets/images/pngs/heyday_ben.png";
import userPatricia from "../../assets/images/svgs/testimony/userPatricia.svg";
import companyWemolo from "../../assets/images/svgs/testimony/companyWemolo.svg";
import userMatthew from "../../assets/images/svgs/testimony/userMatthew.svg";
import companyElastic from "../../assets/images/svgs/testimony/companyElastic.svg";
import { DBT_NAME } from "../../constant";

const TestimonialSlider = ({ setTestimonialImg, isMobile }) => {
  const testimonials = [
    {
      id: 1,
      img: userSilq,
      bigImg: bgSilq,
      name: "Vishnu Nair",
      service: "Co-Founder & CTO",
      companyLogo: iconSilq,
      feedback: (
        <div className="w-full">
          <span className="text-gray-700 font-light leading-6 md:leading-9 break-words  text-[22px]">
            <span className="text-blue-500 font-semibold leading-6 md:leading-9 break-words">
              DataPilot has taken our data documentation and quality tests to
              the next level.{" "}
            </span>
            The auto-generation of column descriptions and data quality tests is
            pure magic! With plain English, our ops team can easily implement
            data tests around the business logic. It's a game-changer!
          </span>
        </div>
      ),
    },
    {
      id: 2,
      img: userHeyDay,
      bigImg: bgHeyDay,
      name: "Ben Goswami",
      service: "Director of Engineering - Data/ML",
      companyLogo: iconHeyDay,
      feedback: (
        <div className="w-full">
          <span className="text-gray-700 font-light leading-6 md:leading-9 break-words   text-[22px]">
            <span className="text-blue-500 font-semibold leading-6 md:leading-9 break-words">
              Automated insights and recommendations on queries is saving us
              time and money.{" "}
            </span>
            Altimate's Query Insights agent serves as expert for SQL quries for
            our data and business teams. Data engineering used to do bulk query
            analysis manually, which was not scalable. Now, AI co-pilot has
            directly enabled individual users to write better queries, resulting
            in cost savings in Snowflake.
          </span>
        </div>
      ),
    },
    {
      id: 3,
      img: userPatricia,
      bigImg: bgWiley,
      name: "Patricia Goldberg",
      service: "Data Engineer",
      companyLogo: companyWemolo,
      feedback: (
        <div className="w-full">
          <span className="text-gray-700 font-light leading-6 md:leading-9 break-words  text-[22px]">
            Having integrated datapilot (power user) into our workflow, I can
            confidently say{" "}
          </span>
          <span className="text-blue-500 font-semibold leading-6 md:leading-9 break-words  text-[22px]">
            it’s been a gamer changer for our team’s {DBT_NAME} development
            process.
          </span>
          <span className="text-gray-700 font-light leading-6 md:leading-9 break-words  text-[22px]">
            {" "}
            The automatic docs generation and query results analysis have been
            incredibly valuable as our data models are now meticulously
            documented and validated, improving the understanding and confidence
            of our business users.
          </span>
        </div>
      ),
    },
    {
      id: 4,
      img: userMatthew,
      bigImg: bgSilq,
      name: "Matthew Skinner",
      service: "Enterprise Data Architect",
      companyLogo: companyElastic,
      feedback: (
        <div className="w-full">
          <span className="text-gray-900 font-light leading-6 md:leading-9 break-words  text-[22px]">
            Utilizing the advanced capabilities of Altimate's Power User for dbt
            has empowered our organization to accelerate our data operations and
            effectively scale delivery across our distributed data teams.{" "}
            <span className="text-blue-500 font-bold leading-6 md:leading-9 break-words">
              Leveraging the artificial intelligence functionalities within this
              tool has significantly enhanced our ability to augment
              productivity{" "}
            </span>
            and foster collaboration among the users of our data platform. We
            have automated a bunch of things with Power User for dbt
            (DataPilot)—our {DBT_NAME} model development and testing are much
            faster because of it. Power User for {DBT_NAME} is also helping us
            embed best practices in the development workflow with their AI
            features with the context of our data stack.
          </span>
        </div>
      ),
    },
  ];
  return (
    <Carousel
      autoplay={false}
      dragging={false}
      wrapAround={true}
      arrows={false}
      loop={true}
      fade={true}
      renderCenterLeftControls={({ previousSlide, currentSlide }) => (
        <button
          className={`${
            isMobile
              ? "absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2"
              : ""
          } bg-[#247EFE] text-white rounded-full w-[40px] h-[40px] flex justify-center items-center hover:scale-90`}
          onClick={() => {
            previousSlide();
            setTestimonialImg(
              testimonials[
                (currentSlide + testimonials.length - 1) % testimonials.length
              ].bigImg,
            );
          }}
        >
          <FiArrowLeft />
        </button>
      )}
      renderCenterRightControls={({ nextSlide, currentSlide }) => (
        <button
          className={`${
            isMobile
              ? "absolute bottom-0 right-1/2 transform translate-x-1/2 translate-y-1/2"
              : ""
          } bg-[#247EFE] text-white rounded-full w-[40px] h-[40px] flex justify-center items-center hover:scale-90`}
          onClick={() => {
            nextSlide();
            setTestimonialImg(
              testimonials[(currentSlide + 1) % testimonials.length].bigImg,
            );
          }}
        >
          <FiArrowRight />
        </button>
      )}
      renderBottomCenterControls={({ currentSlide }) => null}
    >
      {testimonials.map((testimonial, index) => (
        <div
          className="sliderCard bg-white border-l-[12px] border-l-[#247EFE] p-[40px] md:pl-[60px] ml-1"
          key={index}
        >
          <div className="lg:flex justify-between lg:flex-row flex-col-reverse items-center">
            <div className="flex justify-start items-start">
              <img src={testimonial.img} className="mr-3" alt="testimonial" />
              <div>
                <p className="text-lg text-[#247EFE]">{testimonial.name}</p>
                <h2 className="text-lg text-[#082247] font-semibold">
                  {testimonial.service}
                </h2>
              </div>
            </div>

            <img src={testimonial.companyLogo} alt="companyLogo" />
          </div>
          <div className="mt-[24px]">{testimonial.feedback}</div>
        </div>
      ))}
    </Carousel>
  );
};

export default TestimonialSlider;
