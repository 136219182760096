import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { TermsContent } from "./terms.md";
import { PrivacyContent } from "./privacy.md";
import Header from "../../components/header";

const nameDocMapping = {
  terms: TermsContent,
  privacy: PrivacyContent,
};

export const Documents = ({ docType }) => (
  <div className="custom-container">
    <Header />
    <div className="markdown document-style">
      <ReactMarkdown remarkPlugins={[gfm]} children={nameDocMapping[docType]} />
    </div>
  </div>
);
