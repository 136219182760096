import React, { useState } from "react";
import { Link } from "react-router-dom";
import check from "../../dbt_assets/images/check.svg";
import { LuDownload } from "react-icons/lu";

import tm from "../../dbt_assets/images/tm.svg";
import Header from "../header";
import { useQuery } from "react-query";
import {
  defaultDownloadsCount,
  formatNumber,
  processVSCodeDownloadsCount,
} from "../utils/utils";
import { vsCodeDBTDownloads } from "../utils/backendHelper";
import { DBT_NAME } from "../../constant";

const VSCODE_DBT_DOWNLOADS = "VSCODE-DBT-DOWNLOADS";

const dbtDevPoints = [
  {
    setSlide: 0,
    scrollHref: "col1",
    title: <p>Generate {DBT_NAME} models from SQL or config</p>,
  },
  {
    setSlide: 1,
    scrollHref: "col2",
    title: <>Document tables, columns, and {DBT_NAME} models</>,
  },
  {
    setSlide: 2,
    scrollHref: "col3",
    title:
      "Debug by generating data tests, column lineage, and query explanations",
  },
  {
    setSlide: 3,
    scrollHref: "col4",
    title: "Advise on best practices and organizational governance",
  },
  // {
  //   setSlide: 4,
  //   scrollHref: "col5",
  //   title: "Follow best practices via project health checks.",
  // },
];

const DBTHeader = () => {
  const [menuClass, setMenuClass] = React.useState("");
  const isMobile = window.innerWidth <= 900;

  const toggleMenu = () =>
    setMenuClass((_menu) => (_menu === "" ? "active" : ""));

  // TODO: update the testimonials
  const testimonials = [
    {
      id: 1,
      name: "Isaak Anderson",
      feedback: (
        <p className="text-[#333]">
          I would <b className="text-[#FF754C]">loose countless hours</b> at my
          job <b className="text-[#FF754C]">without this tool</b>
        </p>
      ),
    },
    {
      id: 2,
      name: "Carl Muller",
      feedback: (
        <p className="text-[#333]">
          Has become an absolutely{" "}
          <b className="text-[#FF754C]">Indispensable tools for developing</b>
        </p>
      ),
    },
  ];

  const [dbtInstalls, setDBTInstalls] = useState(
    formatNumber(defaultDownloadsCount * 1000),
  );
  useQuery(VSCODE_DBT_DOWNLOADS, vsCodeDBTDownloads, {
    onSuccess: (data) => {
      setDBTInstalls(formatNumber(processVSCodeDownloadsCount(data)));
    },
  });

  return (
    <div className="dbt-hero header">
      <div className="custom-container">
        <Header />
        <div className={`menu-bg ${menuClass}`} onClick={toggleMenu}></div>

        {/* hero */}
        <section className="hero md:pt-[80px] pt-8 md:pb-[100px]">
          <div className="flex mt-[100px] md:flex-row flex-col justify-between items-center">
            <div className="w-full text-center md:text-left md:w-[50%] mb-8">
              <h1 className="text-[#082247] md:text-[48px] text-[36px] font-[600]">
                Experience Next-gen Analytics Engineering with AI Teammates
              </h1>
              {isMobile && (
                <div className="w-full pl-5">
                  <div className="flex flex-col items-left text-left  gap-1 links mt-[36px]">
                    {dbtDevPoints.map((point, index) => (
                      <a
                        href={`#${point.scrollHref}`}
                        key={index}
                        className="text-[#5E7493] hover:text-[#3F8CFF] inline-flex justify-start items-center rounded-md"
                      >
                        <div className="hover:rounded-[10px] hover:bg-[#3F8CFF1A] flex gap-2 justify-between px-2 py-2">
                          <img src={check} className="check" alt="" />{" "}
                          <span className="">{point.title}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex flex-col md:flex-row md:gap-4 md:mt-[30px] gap-4 mt-[40px] mb-[48px]">
                <div className="md:text-left text-center">
                  <Link to="https://marketplace.visualstudio.com/items?itemName=innoverio.vscode-dbt-power-user">
                    <button className="hover:bg-[#3677D9] bg-[#3F8CFF] px-[20px] py-[12px] text-white rounded-[10px] md:mb-0 md:rounded-md text-[16px] font-medium">
                      Power User for {DBT_NAME} VSCode Extension
                    </button>
                  </Link>
                </div>
              </div>
              {!isMobile && (
                <div className="w-full">
                  <div className="flex flex-col items-left text-left  gap-1 links mt-[36px]">
                    {dbtDevPoints.map((point, index) => (
                      <a
                        href={`#${point.scrollHref}`}
                        key={index}
                        className="text-[#5E7493] hover:text-[#3F8CFF] inline-flex justify-start items-center rounded-md"
                      >
                        <div className="hover:rounded-[10px] hover:bg-[#3F8CFF1A] flex gap-2 justify-between px-2 py-2">
                          <img src={check} className="check" alt="" />{" "}
                          <span className="">{point.title}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full mb-16 md:mb-0 md:w-[50%]">
              <div className="testimonial-card">
                <div className="card relative flex flex-col items-center">
                  <div
                    className="w-[85%] h-[262px] md:w-[85%] md:h-[496px] bg-[#082247] rounded-[20px] backdrop-blur-[50px]"
                    alt=""
                  ></div>

                  <img
                    src={tm}
                    className="w-[100px] h-[128px] top-1/3 md:w-[160px] md:h-[204px] right-0 absolute md:-right-[50px] md:top-[30%] -translate-y-[50%] card-info"
                    alt=""
                  />

                  <div className="testimonials w-[70%] md:w-[90%] flex flex-col gap-[30px] md:h-full absolute top-[14%] md:top-[24%] -left-[10px]">
                    {testimonials.map((item, index) => (
                      <div
                        key={index}
                        className={`${
                          index === 1 ? "ml-auto" : "mr-auto"
                        } max-w-[90%] md:max-w-[70%] holder`}
                      >
                        <div className="max-h-[90px] md:max-h-full flex justify-start items-start bg-white rounded-[12px] shadow p-[6px] md:p-[12px] gap-[6px] md:gap-[12px]">
                          <div className="avatar text-[26px] min-w-[60px] md:min-w-[94px] h-[60px] md:h-[94px] bg-[#04BEFE33] rounded-[12px] flex justify-center items-center">
                            {/* first laters of each word of the name */}
                            {item.name &&
                              item.name.split(" ").map((word, index) => (
                                <span
                                  className="uppercase text-[22px] md:text-4xl font-bold text-[#9D9D9D]"
                                  key={index}
                                >
                                  {word[0]}
                                </span>
                              ))}
                          </div>

                          <div className="feedback text-[10px] md:text-[16px]">
                            <p className="text-black font-bold text-[10px] md:text-[18px] md:mb-1 md:mb-[7px]">
                              {item.name}
                            </p>
                            {item.feedback}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <button className=" btn bg-[#FF754C] text-white h-[25px] md:h-[50px] flex justify-center items-center gap-1 md:gap-2 px-5 py-3 rounded-[10px] border border-white absolute z-10 -top-[12.5px] left-[50%] -translate-x-[50%]">
                    <LuDownload className="" />
                    <span className="text-[10px] md:text-[15px]">
                      {dbtInstalls}+ installs
                    </span>
                  </button>

                  <p className="info text-[8px] md:text-[12px] text-white absolute bottom-[10px] md:bottom-[15px] md:left-[60px] md:ml-[5%]">
                    Note: Above reviews are from VS Code Marketplace
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* hero */}
      </div>
    </div>
  );
};

export default DBTHeader;
