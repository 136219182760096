import React from "react";

import soc2Img from "../../assets/images/svgs/aicpa-soc-2.svg";
import checkMark from "../../assets/images/svgs/check-mark.svg";
import FormSpreeForm from "../formSpreeForm";
import { Link } from "react-router-dom";

// load mailchimp custom <script></script> form in popup

const PrivacyAndSecurity = () => {
  const isMobile = window.innerWidth <= 900;
  const [visibleForm, setVisibleForm] = React.useState(false);
  const loadForm = () => {
    setVisibleForm(true);
  };
  return (
    <>
      {visibleForm && <FormSpreeForm setVisibleForm={setVisibleForm} />}
      <div className="privacy-and-security relative">
        <div className="privacy-and-security-inner text-center md:text-left">
          <h2 className="text-[30px] font-[600] text-black mb-10">
            Trusted by security teams in leading enterprises
          </h2>

          <div className="flex lg:flex-row flex-col lg:justify-between justify-center items-center ">
            <div
              className={`flex ${
                isMobile ? "flex-col" : "flex-row"
              } justify-center items-center gap-[20px]`}
            >
              <img src={soc2Img} alt="SOC2" />
              <div className="flex flex-col gap-4">
                <div
                  className={
                    isMobile
                      ? "flex flex-col items-center gap-[10px]"
                      : "flex flex-row mb-4 gap-[20px]"
                  }
                >
                  <div className="flex flex-row bg-[#f5f9ff] p-2 items-center rounded-[10px]">
                    <img src={checkMark} alt="check-mark" />
                    <div className="ml-2 whitespace-nowrap text-sm">
                      Only Metadata Used
                    </div>
                  </div>
                  <div className="flex flex-row bg-[#f5f9ff] p-2 items-center rounded-[10px]">
                    <img src={checkMark} alt="check-mark" />
                    <div className="ml-2 whitespace-nowrap text-sm">
                      Multi-tenant Architecture
                    </div>
                  </div>
                  <div className="flex flex-row bg-[#f5f9ff] p-2 items-center rounded-[10px]">
                    <img src={checkMark} alt="check-mark" />
                    <div className="ml-2 whitespace-nowrap text-sm">
                      No LLM Training on Your Data
                    </div>
                  </div>
                </div>
                <Link to="/security">
                  <button className="text-white bg-[#3F8CFF] transition-all py-[17px] px-[40px] rounded-[5px] inline-block ">
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyAndSecurity;
