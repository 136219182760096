import autoTuneDemo from "../../assets/images/gifs/home_gifs/platform_engr.gif";
import optimizedQueryDemo from "../../assets/images/gifs/extension_gifs/optimized_query_platform.gif";
import preventCostlyMistakes from "../../assets/images/gifs/extension_gifs/prevent_costly_mistakes.png";
import adminAnonimity from "../../assets/images/gifs/extension_gifs/admin_anonimity.png";

export const platformFeatureColumns = [
  {
    id: "col1",
    title: "Autotune warehouse configs 24/7 for cost savings or optimal perf.",
    point:
      "AI teammates analyze 200+ parameters to set optimal configs for maximum cost savings or performance",
    gifSrc: autoTuneDemo,
    reversed: false,
  },
  {
    id: "col2",
    title: "Pinpoint issues in the query code and recommend optimized query",
    point:
      "DataMates will analyze millions of queries running in your environment and identify biggest cost issues. Then, it will show you how to fix those issues.",
    gifSrc: optimizedQueryDemo,
    reversed: true,
  },
  {
    id: "col3",
    title: "Prevent costly mistakes from data consumers in development",
    point:
      "DataMates are available in VSCode, Git, SaaS UI to advise data consumers only when needed, resulting in prevention of mistakes that can turn into cost and performance nightmares later.",
    gifSrc: preventCostlyMistakes,
    reversed: false,
  },
  {
    id: "col4",
    title: "Perform admin tasks autonomously",
    point:
      "Your common tasks like finding dormant users, detecting unused tables, and analyzing expensive warehouses are all automated",
    gifSrc: adminAnonimity,
    reversed: true,
  },
];
