import axios from "axios";
import { REACT_APP_API_URL } from "../../config";
import MailChecker from "mailchecker";

// Your Mautic instance URL
const baseUrl = REACT_APP_API_URL;

export const setEmailContact = async (email, source) => {
  const contactUrl = `${baseUrl}newsletter/?email=${email}&source=${source}`;

  // Axios GET request
  try {
    const response = await axios.get(contactUrl);
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const validateEmail = (email) => {
  return MailChecker.isValid(email);
};
