import DownArrow from "../../assets/images/svgs/arrow-down-blue.svg";

const techBlocks = [
  { text: "VSCode Extension" },
  { text: "Python Package" },
  { text: "Slack Integration" },
  { text: "SaaS UI" },
];

const DataPilotTechStack = () => (
  <div className="flex justify-center items-center bg-white py-[100px]">
    <div className="md:p-[60px] p-4 bg-gradient-to-t from-[#c7d8ff] to-white rounded-3xl flex-col justify-start items-center flex md:w-4/6 w-11/12">
      <div className="flex-col justify-start items-center flex">
        <div className="text-[#082247] md:text-4xl text-[28px] text-center font-semibold font-['Lexend'] leading-[50px]">
          AI Data Teammates
        </div>
        <div className="flex-col justify-start items-center flex">
          <div className="flex-col justify-start items-center flex">
            <img src={DownArrow} alt="down-arrow" />
            <div className="w-3/5 px-6 h-[27px] rounded-tl-[13.39px] rounded-tr-[13.39px] border-l-2 border-r-2 border-t-2 border-[#1365ff]"></div>
            <div className="px-10 py-[13px] bg-white rounded-xl shadow justify-center items-center gap-2.5 inline-flex">
              <div className="text-center text-[#1365ff] md:text-2xl text-base font-semibold font-['Lexend'] leading-9">
                Powered by Context from Entire Data Stack
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={DownArrow} alt="down-arrow" />

      <div className="p-6 bg-white rounded-xl flex flex-col items-center justify-center gap-5 w-full">
        <div className="md:text-lg text-base font-medium text-[#355177] text-center">
          Available in Day-to-day Tools of Data Teams
        </div>
        <div className="flex md:flex-row flex-col gap-4 flex-nowrap w-full">
          {techBlocks.map((item, index) => (
            <div
              key={index}
              className="flex-1 h-[61px] p-4 bg-[#1263ff] rounded-lg flex items-center justify-center"
            >
              <div className="text-lg font-semibold text-white text-center">
                {item.text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default DataPilotTechStack;
