// User LOGOs
// Row 1
import booking from "../../assets/images/svgs/companies/strip1/booking.svg";
import carta from "../../assets/images/svgs/companies/strip1/carta.svg";
import chatbooks from "../../assets/images/svgs/companies/strip1/chatbooks.svg";
import clicksign from "../../assets/images/svgs/companies/strip1/clicksign.svg";
import ooni from "../../assets/images/svgs/companies/strip1/ooni.svg";
import outreach from "../../assets/images/svgs/companies/strip1/outreach.svg";
import siemens from "../../assets/images/svgs/companies/strip1/siemens.svg";
import sorare from "../../assets/images/svgs/companies/strip1/sorare.svg";
import spotify from "../../assets/images/svgs/companies/strip1/spotify.svg";
import grafana from "../../assets/images/svgs/companies/strip1/grafana.svg";

// Row 2
import capgemini from "../../assets/images/svgs/companies/strip2/capgemini.svg";
import dazn from "../../assets/images/svgs/companies/strip2/dazn.svg";
import elastico from "../../assets/images/svgs/companies/strip2/elastico.svg";
import expedock from "../../assets/images/svgs/companies/strip2/expedock.svg";
import glossgenius from "../../assets/images/svgs/companies/strip2/glossgenius.svg";
import heyday from "../../assets/images/svgs/companies/strip2/heyday.svg";
import lighttricks from "../../assets/images/svgs/companies/strip2/lighttricks.svg";
import peloton from "../../assets/images/svgs/companies/strip2/peloton.svg";
import ritchie from "../../assets/images/svgs/companies/strip2/ritchie.svg";
// import usfoods from "../../assets/images/svgs/companies/strip2/usfoods.svg";
import worldbooks from "../../assets/images/svgs/companies/strip2/worldbooks.svg";

// Row 3
import atmosphere from "../../assets/images/svgs/companies/strip3/atmosphere.svg";
import drive from "../../assets/images/svgs/companies/strip3/drive.svg";
import explorium from "../../assets/images/svgs/companies/strip3/explorium.svg";
import juvo from "../../assets/images/svgs/companies/strip3/juvo.svg";
import lucidcharts from "../../assets/images/svgs/companies/strip3/lucidcharts.svg";
import qover from "../../assets/images/svgs/companies/strip3/qover.svg";
import rinsed from "../../assets/images/svgs/companies/strip3/rinsed.svg";
import silq from "../../assets/images/svgs/companies/strip3/silq.svg";
import stubhub from "../../assets/images/svgs/companies/strip3/stubhub.svg";
import vacasa from "../../assets/images/svgs/companies/strip3/vacasa.svg";

// Partner LOGOs
// Row 1
import googlecloud from "../../assets/images/svgs/partners/strip1/googlecloud.svg";
import azure from "../../assets/images/svgs/partners/strip1/azure.svg";
import dbt from "../../assets/images/svgs/partners/strip1/dbt.svg";
import slack from "../../assets/images/svgs/partners/strip1/slack.svg";
import snowflake from "../../assets/images/svgs/partners/strip1/snowflake.svg";

// Row 2
import alton from "../../assets/images/svgs/partners/strip2/alton.svg";
import aws from "../../assets/images/svgs/partners/strip2/aws.svg";
import collibra from "../../assets/images/svgs/partners/strip2/collibra.svg";
import databricks from "../../assets/images/svgs/partners/strip2/databricks.svg";
import github from "../../assets/images/svgs/partners/strip2/github.svg";

export const USER_COMPANIES = {
  strip1: {
    logos: [
      booking,
      carta,
      chatbooks,
      clicksign,
      ooni,
      outreach,
      siemens,
      sorare,
      spotify,
      grafana,
    ],
    reverseOrder: false,
  },
  strip2: {
    logos: [
      capgemini,
      dazn,
      elastico,
      expedock,
      glossgenius,
      heyday,
      lighttricks,
      peloton,
      ritchie,
      // usfoods,
      worldbooks,
    ],
    reverseOrder: true,
  },
  strip3: {
    logos: [
      atmosphere,
      drive,
      explorium,
      juvo,      
      lucidcharts,
      qover,
      rinsed,
      silq,
      stubhub,
      vacasa,
    ],
    reverseOrder: false,
  },
};

export const PARTNER_COMPANIES = {
  strip1: {
    logos: [azure, googlecloud, snowflake, dbt, slack],
  },
  strip2: {
    logos: [databricks, github, collibra, aws, alton],
  },
};
