import { Children } from "react";
import { Tooltip } from "react-tooltip";

import checkMark from "../../assets/images/svgs/check-mark-1.svg";
import infoCircle from "../../assets/images/svgs/info_circle.svg";
import { DBT_NAME } from "../../constant";

const EverythingPlus = ({ prevPlanName }) => (
  <div className="bg-blue-500 bg-opacity-10 rounded-[26.67px] border-r-2 px-3">
    <div className="text-blue-500 text-[20px] font-medium font-['Lexend'] leading-10">
      Everything in {prevPlanName} +
    </div>
  </div>
);

const PlanBullets = ({ children }) => (
  <div className="w-full h-full justify-start items-center space-x-2 inline-flex flex-col">
    {Children.map(children, (child, index) => (
      <div className="w-full h-full justify-start items-start space-x-2 inline-flex">
        {index === 0 && (
          <div className="mt-[2px]">
            <img src={checkMark} alt="check-mark" />
          </div>
        )}
        <div className="w-72 opacity-80 text-[#082247] text-lg font-light leading-6 break-words">
          {child}
        </div>
      </div>
    ))}
  </div>
);

const defaultInfoText =
  "These features are available as a “preview” and may change in the future. Preview features are marked by the preview icon or label in the extensions and in the SaaS UI";
const PlanToolTips = ({ anchorID, text = defaultInfoText }) => (
  <Tooltip
    className="price-info-tooltip"
    anchorSelect={`#${anchorID}`}
    place="top"
    style={{
      backgroundColor: "white",
      color: "black",
    }}
  >
    {text}
  </Tooltip>
);

export const Plans = [
  {
    title: {
      text: "Community",
      pos: "first",
      btn: {
        text: "Sign Up for Free",
        link: "https://app.myaltimate.com/register",
      },
    },
    price: "$0/month",
    executions: [
      <PlanBullets>
        <>
          Starts with <b>200</b> credits grant
        </>
      </PlanBullets>,
      <PlanBullets>
        <>
          After first month, limits at <b>100</b> credits per month
        </>
      </PlanBullets>,
    ],
    functionality: [
      <PlanBullets>dbt models, docs, tests generation</PlanBullets>,
      <PlanBullets>Query translation, explanation, preview</PlanBullets>,
      <PlanBullets>Real-time column lineage</PlanBullets>,
      <PlanBullets>Defer to prod (local)</PlanBullets>,
      <PlanBullets>Project governance (local)</PlanBullets>,
      <PlanBullets>Smart-complete and quick navigation</PlanBullets>,
      <PlanBullets>
        <div className="flex flex-row gap-2 items-center text-[#082247] opacity-80">
          {DBT_NAME} Cloud integration
          <img
            id="starter-plan-quota-info"
            src={infoCircle}
            className="cursor-pointer"
            alt="info-circle"
          />
        </div>
        <PlanToolTips
          anchorID="starter-plan-quota-info"
          text={`${DBT_NAME} Cloud integration is treated in a same way as ${DBT_NAME} Core integration. There are no credits counted just for ${DBT_NAME} Cloud integration.`}
        />
      </PlanBullets>,
      <PlanBullets>Access to preview features</PlanBullets>,
    ],
    aiAgentInterfaces: [
      <PlanBullets>VSCode extension</PlanBullets>,
      <PlanBullets>Python package</PlanBullets>,
    ],
    support: [<PlanBullets>Community slack Channel</PlanBullets>],
  },
  {
    title: {
      text: "Pro",
      pos: "",
      btn: {
        text: "Sign Up & Get Subscription",
        link: "https://app.myaltimate.com/register",
      },
    },
    price: "Starts at $29/month",
    executions: [
      <PlanBullets>
        <>
          Starts at <b>200</b> credits per month
        </>
      </PlanBullets>,
      <PlanBullets>
        <>
          Limits at <b>500</b> credits per month
        </>
      </PlanBullets>,
    ],
    functionality: [<EverythingPlus prevPlanName="Community" />],
    aiAgentInterfaces: [
      <PlanBullets>VSCode extension</PlanBullets>,
      <PlanBullets>Python package</PlanBullets>,
    ],
    support: [<PlanBullets>Community slack channel</PlanBullets>],
  },
  {
    title: {
      text: "Team",
      pos: "",
      btn: {
        text: "Sign Up & Get Subscription",
        link: "https://app.myaltimate.com/register",
      },
    },
    price: "Starts at $549/month",
    executions: [
      <PlanBullets>
        <>
          Starts at <b>750</b> credits per month
        </>
      </PlanBullets>,
      <PlanBullets>
        <>
          Limits at <b>1500</b> credits per month
        </>
      </PlanBullets>,
    ],
    functionality: [
      <EverythingPlus prevPlanName="Pro" />,
      <PlanBullets>Defer to prod (SaaS)</PlanBullets>,
      <PlanBullets>Project governance (SaaS)</PlanBullets>,
    ],
    aiAgentInterfaces: [
      <PlanBullets>VSCode extension</PlanBullets>,
      <PlanBullets>Python package</PlanBullets>,
      <PlanBullets>SaaS UI</PlanBullets>,
    ],
    support: [
      <PlanBullets>Community slack channel</PlanBullets>,
      <PlanBullets>Support over Chatbot</PlanBullets>,
    ],
  },
  {
    title: {
      text: "Enterprise",
      pos: "last",
      btn: {
        text: "Contact Us",
        link: "https://www.altimate.ai/support",
      },
    },
    price: "Custom",
    executions: [
      <PlanBullets>Custom no. of credits</PlanBullets>,
      <PlanBullets>Annual credits limit</PlanBullets>,
    ],
    functionality: [
      <EverythingPlus prevPlanName="Team" />,
      <PlanBullets>Additional AI agents</PlanBullets>,
      <PlanBullets>Warehouse cost savings module</PlanBullets>,
      <PlanBullets>
        Enterprise security (RBAC, Configurable security, SSO)
      </PlanBullets>,
    ],
    aiAgentInterfaces: [
      <PlanBullets>VSCode extension</PlanBullets>,
      <PlanBullets>Python package</PlanBullets>,
      <PlanBullets>SaaS UI</PlanBullets>,
      <PlanBullets>Slack</PlanBullets>,
      <PlanBullets>API support</PlanBullets>,
    ],
    support: [
      <PlanBullets>Dedicated Slack Channel</PlanBullets>,
      <PlanBullets>Support over Video</PlanBullets>,
      <PlanBullets>Priority Support with SLAs</PlanBullets>,
      <PlanBullets>Custom Contracts (MSA, DPA)</PlanBullets>,
      <PlanBullets>Security review</PlanBullets>,
      <PlanBullets>Training Services</PlanBullets>,
    ],
  },
];
