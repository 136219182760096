import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PARTNER_COMPANIES, USER_COMPANIES } from "./company_logos";

const SlidingUsers = ({ images, reverseOrder, slidingEffect }) => {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 10,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 3,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 6,
      partialVisibilityGutter: 30,
    },
  };
  return (
    <Carousel
      additionalTransfrom={0}
      arrows={!slidingEffect}
      autoPlay={slidingEffect}
      autoPlaySpeed={1}
      centerMode={true}
      className=""
      containerClass="container-with-dots"
      customTransition="all 6500ms linear"
      draggable
      focusOnSelect={false}
      infinite={slidingEffect}
      itemClass="partner-user-companies"
      keyBoardControl
      minimumTouchDrag={1}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsive}
      rewind={false}
      rewindWithAnimation={false}
      rtl={reverseOrder}
      shouldResetAutoplay={false}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      transitionDuration={6500}
    >
      {images.map((src, index) => (
        <img key={index} src={src} alt="company" draggable={false} />
      ))}
    </Carousel>
  );
};

export const UserCompanies = () => (
  <div className="user-companies py-[60px] md:py-[120px]">
    <div className="text-[#082247] text-center font-semibold text-[30px] md:text-[42px] mb-[24px]">
      Join 100s of Data Teams that have DataMates
    </div>

    {Object.keys(USER_COMPANIES).map((key) => (
      <SlidingUsers
        key={key}
        images={USER_COMPANIES[key].logos}
        reverseOrder={USER_COMPANIES[key].reverseOrder}
        slidingEffect={true}
      />
    ))}
  </div>
);

export const PartnerCompanies = ({ isMobile }) => (
  <div className="partner-companies md:py-[120px] py-[60px] md:mb-[30px]">
    <div className="text-[#082247] text-center font-semibold text-[30px] md:text-[42px] mb-[42px]">
      Our Partners
    </div>

    <div className="logo-container">
      {Object.values(PARTNER_COMPANIES).map((strip, stripIndex) => (
        <div key={stripIndex} className="logos">
          {strip.logos.map((Logo, logoIndex) => (
            <img
              className={isMobile ? "h-20 w-full object-contain" : ""}
              src={Logo}
              key={`${stripIndex}-${logoIndex}`}
              alt={`${stripIndex}-${logoIndex}`}
            />
          ))}
        </div>
      ))}
    </div>
  </div>
);
