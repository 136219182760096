import React, { useState } from "react";
import CountUp from "react-countup";
import counter1 from "../../assets/images/svgs/counter1.svg";
import counter2 from "../../assets/images/svgs/counter2.svg";
// import counter3 from "../../assets/images/svgs/counter3.svg";
import counter4 from "../../assets/images/svgs/counter4.svg";

import { FiArrowUp } from "react-icons/fi";
import { useQuery } from "react-query";
import {
  defaultDownloadsCount,
  formatNumberK,
  processVSCodeDownloadsCount,
} from "../utils/utils";
import { vsCodeDBTDownloads } from "../utils/backendHelper";

const HOME_DBT_DOWNLOADS = "HOME-DBT-DOWNLOADS";

const Counter = () => {
  const [extDownloads, setExtDownloads] = useState(defaultDownloadsCount);
  useQuery(HOME_DBT_DOWNLOADS, vsCodeDBTDownloads, {
    onSuccess: (data) => {
      setExtDownloads(formatNumberK(processVSCodeDownloadsCount(data)));
    },
  });
  const counterData = [
    {
      id: 1,
      value: extDownloads,
      image: counter1,
      extension: "k+",
      text: (
        <p className="text-white md:text-lg text-left font-[400] md:mb-1">
          Downloads
        </p>
      ),
    },
    {
      id: 1,
      value: 8,
      image: counter2,
      extension: "M+",
      text: (
        <p className="text-white md:text-lg text-left font-[400] md:mb-1">
          DataPilot executions this week
        </p>
      ),
    },
    {
      id: 1,
      value: 9,
      image: counter4,
      extension: "hrs",
      text: (
        <p className="text-white md:text-lg text-left font-[400] md:mb-1">
          Saved per user this week
        </p>
      ),
    },
  ];
  return (
    <div className="counter bg-[#082247] py-[62px]">
      <div className="custom-container">
        <div className="flex lg:flex-row flex-col justify-between items-center gap-lg">
          {counterData.map((counter, index) => (
            <div
              key={index}
              className={`${
                index === 2 ? "counter-div2" : "counter-div"
              } text-center flex `}
            >
              <div className="h-full">
                <img className="mt-5 md:mt-2" src={counter.image} alt="" />
              </div>
              <div className="flex-col mb-4 md:mb-0">
                <div className="flex align-center">
                  <h2 className="md:text-6xl text-[48px] font-lexend text-white mx-4">
                    <CountUp
                      className=" font-[600]"
                      end={counter.value}
                      duration={5}
                    />
                    <span className="font-[600] ">{counter.extension}</span>
                  </h2>

                  <FiArrowUp className="text-[#01CD8C] text-2xl mt-4" />
                </div>
                <div className="ms-4">{counter.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Counter;
