import React from "react";
import { Link, NavLink } from "react-router-dom";

// import logo from "../../assets/images/gifs/Logo Animation.gif";
import arrow from "../../assets/images/svgs/arrow.svg";
import { FaBars } from "react-icons/fa";
import FormSpreeForm from "../formSpreeForm";
import { REGISTER_LINK } from "../../constant";
import AltimateLogoComponent from "../companies/company_logo_animation";

const Header = () => {
  const isMobile = window.innerWidth <= 768;
  const [menuActive, setMenuActive] = React.useState("");
  const [visibleForm, setVisibleForm] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const timeoutRef = React.useRef(null);

  const menuToggler = () => {
    menuActive === "" ? setMenuActive("active") : setMenuActive("");
  };

  // const loadForm = () => {
  //   setVisibleForm(true);
  // };

  const isFixed = true;

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY > 10) {
  //       setIsFixed(true);
  //     } else {
  //       setIsFixed(false);
  //     }
  //   });
  // }, []);

  const handleDropdownEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleDropdownLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const handleDropdownShow = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      {visibleForm && <FormSpreeForm setVisibleForm={setVisibleForm} />}
      <header className={`py-8 ${isFixed ? "fixed" : ""}`}>
        <div
          className={`flex justify-between items-center ${
            isFixed ? "header-container" : ""
          }`}
        >
          <Link to="/">
            {/* <img src={logo} className="h-[32px]" alt="logo" /> */}
            <AltimateLogoComponent />
          </Link>
          <div className={`nav-bg ${menuActive}`} onClick={menuToggler}></div>
          <ul
            className={`flex justify-end items-center nav-menu ${menuActive}`}
          >
            <li className="mx-4">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#3F8CFF] nav-item hover:text-[#247EFE]"
                    : "text-[#082247B2] nav-item hover:text-[#247EFE]"
                }
                to="/"
              >
                DataMates
              </NavLink>
            </li>
            <li className="mx-4">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#3F8CFF] nav-item hover:text-[#247EFE]"
                    : "text-[#082247B2] nav-item hover:text-[#247EFE]"
                }
                to="/analytics"
              >
                Analytics Engineering
              </NavLink>
            </li>
            <li className="mx-4">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#3F8CFF] nav-item hover:text-[#247EFE]"
                    : "text-[#082247B2] nav-item hover:text-[#247EFE]"
                }
                to="/platform"
              >
                Platform Engineering
              </NavLink>
            </li>
            <li className="mx-4">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#3F8CFF] nav-item hover:text-[#247EFE]"
                    : "text-[#082247B2] nav-item hover:text-[#247EFE]"
                }
                to="/pricing"
              >
                Pricing
              </NavLink>
            </li>
            <li
              className="mx-4 text-[#082247B2] cursor-pointer relative group"
              onMouseEnter={handleDropdownEnter}
              onMouseLeave={handleDropdownLeave}
              onClick={handleDropdownShow}
            >
              <div className="flex content-center">
                Resources
                <img
                  src={arrow}
                  className="mt-5 md:mt-1 ms-2 w-[20px] h-[20px]"
                  alt="arrow"
                />
              </div>
              <ul
                className={`${
                  isOpen ? "block" : "hidden"
                } w-[150px] md:w-auto absolute z-10 mt-2 py-2 bg-[#E8F1F9] rounded-md`}
              >
                <li>
                  <a
                    className="block px-4 py-2 text-[rgba(8, 34, 71, 0.70)] text-14 hover:text-[#247EFE]"
                    href="/videos"
                  >
                    Tutorials
                  </a>
                </li>
                <li>
                  <a
                    className="block px-4 py-2 text-[rgba(8, 34, 71, 0.70)] text-14 hover:text-[#247EFE]"
                    href="http://docs.myaltimate.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Docs
                  </a>
                </li>
                {/* <li>
                  <a
                    className="block px-4 py-2 text-[rgba(8, 34, 71, 0.70)] text-14"
                    href="/blog"
                  >
                    Blog
                  </a>
                </li> */}
                <li>
                  <a
                    className="block px-4 py-2 text-[rgba(8, 34, 71, 0.70)] text-14 hover:text-[#247EFE]"
                    href="/about"
                  >
                    About us
                  </a>
                </li>
                <li>
                  <a
                    className="block px-4 py-2 text-[rgba(8, 34, 71, 0.70)] text-14 hover:text-[#247EFE]"
                    href="/support"
                  >
                    Support
                  </a>
                </li>
                <li>
                  <a
                    className="block px-4 py-2 text-[rgba(8, 34, 71, 0.70)] text-14 hover:text-[#247EFE]"
                    href="https://blog.altimate.ai/"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    className="block px-4 py-2 text-[rgba(8, 34, 71, 0.70)] text-14 hover:text-[#247EFE]"
                    href="/security"
                  >
                    Security
                  </a>
                </li>
                {/* <li>
                  <a
                    className="block px-4 py-2 text-[rgba(8, 34, 71, 0.70)] text-14 hover:text-[#247EFE]"
                    href="/technology"
                  >
                    Technology
                  </a>
                </li> */}
              </ul>
            </li>
            {isMobile && (
              <li className="mx-4">
                <Link to={REGISTER_LINK}>
                  <button
                    className={
                      "text-[#5E7493] nav-item border border-[#B7C4D7] rounded px-[24px] mt-4 md:mt-0 md:py-[11px] hover:bg-[#3F8CFF] hover:text-white hover:border-[#3F8CFF]"
                    }
                  >
                    Start for Free
                  </button>
                </Link>
              </li>
            )}
          </ul>
          {!isMobile && (
            <Link to={REGISTER_LINK}>
              <button
                className={
                  "text-[#5E7493] nav-item border border-[#B7C4D7] rounded px-[24px] mt-4 md:mt-0 md:py-[11px] hover:bg-[#3F8CFF] hover:text-white hover:border-[#3F8CFF]"
                }
              >
                Start for Free
              </button>
            </Link>
          )}

          {/* check if the window width is <768px or not */}
          <button
            className="md:hidden focus:outline-none"
            onClick={menuToggler}
          >
            <FaBars className="text-black text-2xl" />
          </button>
        </div>
      </header>
    </>
  );
};

export default Header;
