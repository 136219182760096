import React, { useEffect, useState } from "react";
import { Plans } from "./plans";
import { Link } from "react-router-dom";

const PlanCols = () => (
  <div className="w-[160px] flex flex-col justify-center align-center">
    <div className="w-full h-[66px] text-[#3F8CFF] font-[500] text-lg font-medium leading-6 break-words p-[20px] border border-[#DFE3E9] border-r-0 mt-[135px] rounded-tl-[8px] bg-[#F5F8FF]">
      Price
    </div>
    <div className="w-full h-[230px] text-[#3F8CFF] font-[500] text-lg font-medium leading-6 break-words p-[20px] border border-[#DFE3E9] border-r-0 bg-[#F5F8FF]">
      Credits
    </div>
    <div className="w-full h-[400px] text-[#3F8CFF] font-[500] text-lg font-medium leading-6 break-words p-[20px] border border-[#DFE3E9] border-r-0 bg-[#F5F8FF]">
      Functionality
    </div>
    <div className="w-full h-[200px] text-[#3F8CFF] font-[500] text-lg font-medium leading-6 break-words p-[20px] border border-[#DFE3E9] border-r-0 bg-[#F5F8FF]">
      AI Agent Interfaces
    </div>
    <div className="w-full h-[260px] text-[#3F8CFF] font-[500] text-lg font-medium leading-6 break-words p-[20px] border border-[#DFE3E9] border-r-0 rounded-bl-[8px] bg-[#F5F8FF]">
      Support
    </div>
  </div>
);

const PlansWeb = ({
  idx,
  title,
  price,
  executions,
  functionality,
  aiAgentInterfaces,
  support,
  activeIdx,
}) => {
  const isHovered = activeIdx === idx;
  return (
    <div
      className={`inflex-flex flex-col justify-center align-center ${
        isHovered
          ? "border border-[#3F8CFF] bg-[#F5F8FF]"
          : "border border-[#DFE3E9] border-l-0"
      } ${title.pos === "first" && "rounded-tl-[9px]"} ${
        title.pos === "last" && "rounded-tr-[9px]"
      } ${
        title.pos === "first"
          ? "rounded-tl-[9px]"
          : title.pos === "last"
          ? "rounded-tr-[9px]"
          : ""
      }`}
    >
      <div
        className={`h-[135px] inflex-flex flex-col align-start border-b border-[#DFE3E9] ${
          title.pos === "first"
            ? `rounded-tl-[9px] ${!isHovered && "border-l"}`
            : title.pos === "last"
            ? "rounded-tr-[9px]"
            : ""
        } ${
          isHovered ? "bg-[#3F8CFF]" : "bg-[#F5F7F9]"
        } py-[24px] pl-[24px] gap-4`}
      >
        <div
          className={`${
            isHovered ? "text-white" : "text-[#3F8CFF]"
          } text-[24px]`}
        >
          {title.text}
        </div>
        <button
          className={`p-4 ${
            isHovered ? "bg-white" : "bg-[#3F8CFF]"
          } shadow-sm rounded-lg overflow-hidden justify-center items-center space-x-2 inline-flex`}
        >
          <Link to={title.btn.link} target="_blank">
            <div
              className={`${
                isHovered ? "text-[#3F8CFF]" : "text-white"
              } text-sm font-medium leading-5 break-words`}
            >
              {title.btn.text}
            </div>
          </Link>
        </button>
      </div>
      <div className="w-full h-[66px] text-[#082247] text-lg font-medium leading-6 break-words p-[20px] border-b border-[#DFE3E9]">
        {price}
      </div>
      <div className="w-full h-[230px] flex flex-col justify-start items-start space-y-2 inline-flex p-[20px] border-b border-[#DFE3E9]">
        {executions.map((func) => (
          <div className="w-auto">{func}</div>
        ))}
      </div>
      <div className="w-full h-[400px] flex flex-col justify-start items-start space-y-2 inline-flex p-[20px] border-b border-[#DFE3E9]">
        {functionality.map((func) => (
          <div className="w-auto">{func}</div>
        ))}
      </div>
      <div className="w-full h-[200px] flex flex-col justify-start items-start space-y-2 p-[20px] border-b border-[#DFE3E9] plans-container">
        {aiAgentInterfaces.map((func) => (
          <div className="w-auto">{func}</div>
        ))}
      </div>
      <div
        className={`w-full h-[260px] flex flex-col justify-start items-start space-y-2 inline-flex p-[20px] ${
          title.pos === "last" && "rounded-br-[9px]"
        }`}
      >
        {support.map((sup) => (
          <div className="w-auto">{sup}</div>
        ))}
      </div>
    </div>
  );
};

const PlansMobile = ({
  idx,
  title,
  price,
  executions,
  functionality,
  aiAgentInterfaces,
  support,
  activeIdx,
}) => {
  const isActive = activeIdx === idx;
  return (
    <div
      className={`flex flex-col justify-center align-center border rounded-[9px] w-[350px] ${
        isActive ? "border-[#3F8CFF] bg-[#F5F8FF]" : ""
      }`}
    >
      <div
        className={`h-[135px] flex flex-col items-center rounded-t-[9px] ${
          isActive ? "bg-[#3F8CFF]" : "bg-[#F5F7F9]"
        } p-[24px] gap-4`}
      >
        <div
          className={`${
            isActive ? "text-white" : "text-[#3F8CFF]"
          } text-[24px]`}
        >
          {title.text}
        </div>
        <button
          className={`w-full h-full p-4 ${
            isActive ? "bg-white" : "bg-[#3F8CFF]"
          } shadow-sm rounded-lg overflow-hidden justify-center items-center space-x-2 inline-flex`}
        >
          <div
            className={`${
              isActive ? "text-[#3F8CFF]" : "text-white"
            } text-sm font-medium leading-5 break-words`}
          >
            {title.btn.text}
          </div>
        </button>
      </div>
      <div className="w-full h-full flex flex-col justify-start items-start space-y-4 inline-flex p-[20px] border border-b-[#DFE3E9]">
        <div className="w-32 text-blue-500 text-lg font-medium leading-6 break-words">
          Price
        </div>
        <div className="w-full text-[#082247] text-lg font-medium leading-6 break-words">
          {price}
        </div>
      </div>
      <div className="w-full h-full flex flex-col justify-start items-start inline-flex p-[20px] border border-b-[#DFE3E9]">
        <div className="w-32 text-blue-500 text-lg font-medium leading-6 break-words mb-[16px]">
          Credits
        </div>
        {executions.map((func) => (
          <div className="w-auto  my-1">{func}</div>
        ))}
      </div>
      <div className="w-full h-full flex flex-col justify-start items-start inline-flex p-[20px] border border-b-[#DFE3E9]">
        <div className="w-32 text-blue-500 text-lg font-medium leading-6 break-words mb-[16px]">
          Functionality
        </div>
        {functionality.map((func) => (
          <div className="w-auto  my-1">{func}</div>
        ))}
      </div>
      <div className="w-full h-full flex flex-col justify-start items-start inline-flex p-[20px] border border-b-[#DFE3E9]">
        <div className="w-32 text-blue-500 text-lg font-medium leading-6 break-words mb-[16px]">
          AI Agent Interfaces
        </div>
        <div className="w-full flex flex-col justify-start items-start">
          {aiAgentInterfaces}
        </div>
      </div>
      <div className="w-full h-full flex flex-col justify-start items-start inline-flex p-[20px] border border-b-[#DFE3E9]">
        <div className="w-32 text-blue-500 text-lg font-medium leading-6 break-words mb-[16px]">
          Support
        </div>
        {support.map((sup) => (
          <div className="w-auto my-1">{sup}</div>
        ))}
      </div>
    </div>
  );
};

const PricingComponent = () => {
  const [mobileActiveIdx, setMobileActiveIdx] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`flex ${
        isMobile
          ? "flex-col items-center gap-[40px] mb-[60px]"
          : "flex-row items-start mb-[50px] justify-center"
      } gap-0 px-[7.5%]`}
    >
      {!isMobile && (
        <div className="sticky left-0">
          <PlanCols />
        </div>
      )}
      <div
        className={`flex ${
          isMobile ? "flex-col items-center gap-6" : "flex-row items-start"
        } overflow-x-auto`}
      >
        {Plans.map((plan, idx) =>
          isMobile ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setMobileActiveIdx(idx);
              }}
            >
              <PlansMobile idx={idx} activeIdx={mobileActiveIdx} {...plan} />
            </div>
          ) : (
            <div
              onMouseEnter={() => setMobileActiveIdx(idx)}
              onMouseLeave={() => setMobileActiveIdx(-1)}
            >
              <PlansWeb idx={idx} activeIdx={mobileActiveIdx} {...plan} />
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default PricingComponent;
