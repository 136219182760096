import React, { useEffect, useState } from "react";
// import HeroHome from "../../components/hero/hero-home";
import Counter from "../../components/counter";
// import GetStarted from "../../components/getStarted";
import Testimonial from "../../components/testimonial";
// import Workflows from "../../components/workflows";
import PrivacyAndSecurity from "../../components/PrivacyAndSecurity";
// import Integrated from "../../components/Integrated";
import Reviews from "../../components/reviews";
import { useRef, createRef } from "react";

import { useSearchParams } from "react-router-dom";
import { PartnerCompanies, UserCompanies } from "../../components/companies";
// import HomePage1 from "../../components/hero/home-1";
import Header from "../../components/header";
import ProductWorkflow from "../../components/workflows/productFlows";
import HomePage2 from "../../components/hero/home-2";
import DataPilotTechStack from "../../components/dataPilot/dataPilotTechstack2";
import { workflowData } from "./data";

const Home = () => {
  const isMobile = window.innerWidth <= 900;
  const workflowsRefs = useRef(
    Array(workflowData.length)
      .fill(null)
      .map(() => createRef()),
  );

  const [scrollData, setScrollData] = useState(null);

  const handleCardClick = (parentIndex, childIndex) => {
    setScrollData([parentIndex, childIndex]);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const scrollTo = searchParams.get("scroll");

  useEffect(() => {
    if (scrollTo === "1" || scrollTo === "2") {
      const element = workflowsRefs.current[scrollTo].current;
      const offset = window.innerWidth <= 768 ? 10 : 40;

      setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [scrollTo, workflowsRefs]);

  return (
    <div>
      <Header />
      {/* <HomePage1 workflowsRefs={workflowsRefs} /> */}
      <HomePage2 />
      <Counter />
      <UserCompanies />
      <DataPilotTechStack />
      <ProductWorkflow workflowData={workflowData} />
      {/* <DatapilotTechStack /> */}
      <PrivacyAndSecurity />
      <PartnerCompanies isMobile={isMobile} />
      {/* <DatapilotActions /> */}
      <Testimonial />
      <Reviews />
    </div>
  );
};

export default Home;
