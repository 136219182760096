import React from "react";
import { platformFeatureColumns } from "./data";

const PlatformFeaturesColumns = ({ id, reversed, title, point, gifSrc }) => (
  <div
    id={id}
    className={`w-full h-full flex md:flex-row flex-col items-center justify-center md:mt-[140px] mt-6 md:gap-[60px] md:px-0 px-[2.5%] ${
      reversed ? "md:flex-row-reverse" : "md:flex-row"
    }`}
  >
    <div
      className={`md:h-auto md:min-w-[520px] md:max-w-[521px] md:text-left text-center md:mt-0 mt-6`}
    >
      <h2 className="lexend text-[#082247] md md:text-[30px] text-2xl font-bold mb-[10px] leading-[39px]">
        {title}
      </h2>
      <div class="text-[#355177] text-base font-normal font-['Lexend'] leading-relaxed md:mb-0 mb-4">
        {point}
      </div>
    </div>
    <div className="w-[382px] max-h-[250px] md:min-w-[44%] md:max-h-[100%] flex flex-col items-center md:justify-center justify-start">
      <img
        className="max-h-[250px] md:w-full md:max-h-full border rounded-[10px]"
        src={gifSrc}
        alt="Demo GIF"
      />
    </div>
  </div>
);

const PlatformColumns = () => (
  <div className="bg-[#F5F7F9] md:columns md:pb-[100px] pb-[70px]">
    <div className="text-[#082247] font-lexend text-4xl font-semibold text-center mt-[100px] md:mb-[30px] mb-0">
      AI teammates for Specialized Work
    </div>
    {platformFeatureColumns.map((feature, index) => (
      <PlatformFeaturesColumns key={index} {...feature} />
    ))}
  </div>
);

export default PlatformColumns;
