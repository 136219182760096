import React from "react";

import reviews from "../../assets/images/svgs/reviews.svg";

// import review_image1 from "../../assets/images/svgs/review_image1.svg";
// import ReviewBox from "../reviewBox";

const Reviews = () => {
  return (
    <img className="w-full md:mb-[100px] mb-[60px]" src={reviews} alt="" />
  );
};

// const NewReviews = () => {
//   return (
//     <div className="w-full">
//       <ReviewBox
//         name={"Boris"}
//         image={review_image1}
//         review={[
//           "Best tool I have used for vs code, it is",
//           " amazing that it is free",
//         ]}
//       />
//     </div>
//   );
// };

export default Reviews;
