import { Link } from "react-router-dom";
import "./home-2.css";
import { useState, useEffect } from "react";
import { REGISTER_LINK } from "../../constant";

const typingFeatureDescriptions = [
  "Do specific work for you autonomously",
  "Work 24/7 in ambience to scale you",
  "Learn your preferences and instructions",
  "Be always by your side to assist and advise",
];

const features = [
  { text: "2X Faster Data Development" },
  { text: "30% Lower Infra. Costs" },
  { text: "Autonomous Data Governance" },
];

const RightSVG = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 18L15.5 12L9.5 6"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const HomePage2 = () => {
  const [typingText, setTypingText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [isClearing, setIsClearing] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(true);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (!isTyping && !isClearing) return;

      if (isTyping) {
        if (
          typingText.length >= typingFeatureDescriptions[currentIndex].length
        ) {
          setIsTyping(false);
          setTimeout(() => {
            setIsClearing(true);
          }, 4000);
          return;
        }
        setTypingText(
          typingFeatureDescriptions[currentIndex].slice(
            0,
            typingText.length + 1,
          ),
        );
      }

      if (isClearing) {
        if (typingText.length === 0) {
          setIsClearing(false);
          setIsTyping(true);
          setTypingText("");
          setCurrentIndex(
            (currentIndex + 1) % typingFeatureDescriptions.length,
          );
          return;
        }
        setTypingText(typingText.slice(0, typingText.length - 1));
      }
    }, 20);

    return () => clearInterval(typingInterval);
  }, [currentIndex, typingText, isTyping, isClearing]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setCursorVisible(!cursorVisible);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, [cursorVisible]);

  return (
    <div className="homepage text-center mt-[30px]">
      <div className="text-[#3f8cff] md:text-[80px] text-[40px] font-bold font-['Lexend'] md:leading-[88px] leading-[48px]">
        Your (AI) Data Teammates
      </div>
      <div className="text-center mt-4 md:my-10 mb-0">
        <span className="text-[#355177] md:text-4xl text-xl font-medium font-['Lexend'] leading-[50px]">
          {typingText}
        </span>
        <span
          className={`text-[#247efe] text-4xl font-medium font-['Lexend'] leading-[50px] ${
            cursorVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          &nbsp;|
        </span>
      </div>
      <Link to={REGISTER_LINK}>
        <button className="h-[76px] px-[30px] py-5 bg-[#247efe] rounded-lg shadow text-white md:text-2xl text-base font-semibold font-['Lexend'] leading-9 flex justify-center items-center gap-2.5">
          Start for Free
          <RightSVG />
        </button>
      </Link>
      <div className="mt-[90px] flex flex-col md:flex-row md:rounded-2xl rounded-md border-2 border-[#d1e4ff] text-center bg-white justify-center items-center cursor-pointer">
        {features.map((item, index) => (
          <div
            key={index}
            className="w-full md:flex-grow md:w-auto px-[22px] py-4 md:border-r-2 md:border-b-0 border-b-2 border-[#d1e4ff] md:last:border-r-0  last:border-b-0 text-center text-[#5e7493] text-xl font-medium font-['Lexend'] leading-loose"
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage2;
