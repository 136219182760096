import React from "react";
import GetStarted from "../../components/getStarted";
// import CustomHeader from "../../components/customHeader";
import SecurityApproach from "../../components/securityApproach";
import SecurityAdditional from "../../components/securityAdditional";
import FAQ from "../../components/faq";
// import Header from "../../components/header";
import CustomHeader from "../../components/customHeader";

const Security = () => {
  return (
    <div>
      <CustomHeader title={"Security"} heading={"Home / Security"} />
      {/* <Header /> */}
      <SecurityApproach />
      <SecurityAdditional />
      <FAQ />
      <GetStarted />
    </div>
  );
};

export default Security;
