import React from "react";

const ProudOf = () => {
  return (
    <div className="md:pt-[100px] pt-[50px] pb-[50px] bg-white">
      <div className="custom-container">
        <div className="text-center">
          <h2 className="md:text-[42px] text-[24px] font-[600] text-[#0E172C]">
            We are proud of
          </h2>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8 mt-[60px]">
            <div>
              <h2 className="lg:text-[48px] text-[30px] text-[#082247] font-[600]">
                One
              </h2>
              <p className="lg:text-xl text-[#8390A3]">
                Dream of Perfect <br /> Data world
              </p>
            </div>
            <div>
              <h2 className="lg:text-[48px] text-[30px] text-[#082247] font-[600]">
                Three
              </h2>
              <p className="lg:text-xl text-[#8390A3]">
                Continents covered <br />
                by the Team
              </p>
            </div>
            <div>
              <h2 className="lg:text-[48px] text-[30px] text-[#082247] font-[600]">
                Thousands
              </h2>
              <p className="lg:text-xl text-[#8390A3]">
                Of Hours Saved <br />
                for Data Teams
              </p>
            </div>
            <div>
              <h2 className="lg:text-[48px] text-[30px] text-[#082247] font-[600]">
                Millions
              </h2>
              <p className="lg:text-xl text-[#8390A3]">
                In Funding from <br /> top-tier VCs
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProudOf;
