import React from "react";

import dotImg from "../../assets/images/svgs/dot.svg";
import FormSpreeForm from "../formSpreeForm";
import { Link } from "react-router-dom";
import { REGISTER_LINK } from "../../constant";

// load mailchimp custom <script></script> form in popup

const GetStarted = () => {
  const [visibleForm, setVisibleForm] = React.useState(false);
  const loadForm = () => {
    setVisibleForm(true);
  };
  return (
    <>
      {visibleForm && <FormSpreeForm setVisibleForm={setVisibleForm} />}
      <div className="getStarted py-[100px] relative">
        <img
          src={dotImg}
          alt="dot"
          className="absolute bottom-0 left-0 md:block hidden"
        />
        <img
          src={dotImg}
          alt="dot"
          className="absolute top-0 right-0 md:block hidden"
        />
        <div className="custom-container">
          <h2 className="md:text-[42px] text-[24px] font-[600] text-white md:mb-0 mb-5">
            Ready to get started?
          </h2>

          <div className="w-full h-[1px] bg-white my-[30px] lg:block hidden"></div>

          <div className="flex lg:flex-row flex-col lg:justify-between justify-center items-center">
            <p className="md:text-lg lg:text-left md:text-start text-center text-white lg:w-1/2">
              You are only few clicks away from experiencing your own datamates.
              What are you waiting for??
            </p>
            <div className="lg:w-1/2 lg:text-right text-center">
              <Link to={REGISTER_LINK}>
                <button className="text-white bg-[#01CD8C] hover:bg-green-600 transition-all py-[15px] px-[24px] rounded-[5px] inline-block md:mt-0 mt-5">
                  Get Started for Free
                </button>
              </Link>
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </>
  );
};

export default GetStarted;
