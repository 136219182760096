import React from "react";
import GetDBTStarted from "../../components/getDBTStarted";
import DBTHeader from "../../components/dbtheader";
import Resources from "../../components/resources";
import TrustedBy from "../../components/trusted-by";
import DBTColumns from "../../components/dbtcolumns";
import ModalPopUp from "../../components/newsLetterModal";
import { useSearchParams } from "react-router-dom";
import { UserCompanies } from "../../components/companies";

const VSCode = () => {
  const [searchParams] = useSearchParams();
  const isNewsLetterVisible = searchParams.get("source") === "newsletter";
  return (
    <div>
      {isNewsLetterVisible && <ModalPopUp />}
      <DBTHeader />
      <Resources />
      <UserCompanies />
      <TrustedBy />
      <DBTColumns />
      {/* <GetDBTStarted /> */}
    </div>
  );
};

export default VSCode;
