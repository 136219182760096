import React from "react";
import Header from "../header";

const CustomHeader = ({ heading, title }) => {
  return (
    // <div className="bg-white ">
    <div className="custom-header">
      <div className="custom-container">
        <Header />

        <div className="md:py-[60px] pt-[60px] text-center mt-[48px] md:pb-[150px] pb-0">
          <p className="text-[#247efe] text-center font-lexend font-normal">
            {heading}
          </p>
          <h1 className="text-[#082247] text-[42px] font-semibold">{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default CustomHeader;
