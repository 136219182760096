import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/svgs/f-logo.svg";

import linkedIn from "../../assets/images/svgs/linkedin.svg";
import slack from "../../assets/images/svgs/slack.svg";
import github from "../../assets/images/svgs/github.svg";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import { setEmailContact, validateEmail } from "../newsLetterModal/utils";
import { DBT_NAME } from "../../constant";

export const NewsLetterForm = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
    setEmailSent(false);
  };
  return (
    <MailchimpSubscribe
      url="https://altimate.us10.list-manage.com/subscribe/post?u=e3e045441ad183e6171588539&amp;id=0a7e646412&amp;f_id=00dcd4e5f0"
      render={({ subscribe, status, message }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setEmailContact(email, "website_vscodeform");
            subscribe({ EMAIL: email, tags: 13518211 });
            setEmailSent(true);
          }}
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate m-0"
          noValidate
        >
          <div className="mt-5 flex">
            <input
              name="EMAIL"
              className="w-full border rounded-s-md border-[#E5E5E5] rounded-none px-4 py-3 focus:outline-none"
              type="text"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />
            <button
              disabled={emailError !== "" || email === "" || emailSent}
              className={`${
                emailError === "" && email !== ""
                  ? emailSent
                    ? "bg-[#004fbf]"
                    : "bg-[#3f8cff] cursor-pointer"
                  : "bg-[#b7cfd7]"
              } rounded-e-md text-white rounded-none px-6 py-3`}
            >
              Join
            </button>
          </div>
          {emailError === "" ? (
            <div className="mt-4">
              {status === "sending" && "Sending..."}
              {status === "error" && message}
              {status === "success" && "You are subscribed to newsletter"}
            </div>
          ) : (
            email.length >= 5 && (
              <div className="mt-4 text-[#ffcc00]">{emailError}</div>
            )
          )}
        </form>
      )}
    />
  );
};

const Footer = () => {
  return (
    <footer className="pt-20 pb-8">
      <div className="custom-container">
        <div className="flex lg:flex-row flex-col justify-between items-start mb-8">
          <div className="lg:w-8/12 w-full menus lg:flex justify-between items-start">
            <div className="lg:w-1/3 md:block flex justify-between items-center w-full">
              <Link to="/">
                <img src={logo} className="w-[155px] h-[26px]" alt="logo" />
              </Link>

              <div className="flex gap-2 md:mt-5">
                <Link
                  className="h-12 w-12"
                  to="https://www.linkedin.com/company/altimate-ai/"
                >
                  <img
                    className="object-cover w-full h-full hover:filter hover:drop-shadow hover:white"
                    src={linkedIn}
                    alt="linkedIn"
                  />
                </Link>
                <Link
                  className="h-12 w-12"
                  to="https://github.com/AltimateAI/vscode-dbt-power-user"
                >
                  <img
                    className="object-cover w-full h-full hover:filter hover:drop-shadow hover:white"
                    src={github}
                    alt="github"
                  />
                </Link>
                <Link
                  className="h-12 w-12"
                  to="https://getdbt.slack.com/archives/C05KPDGRMDW"
                >
                  <img
                    className="object-cover w-full h-full hover:filter hover:drop-shadow hover:white"
                    src={slack}
                    alt="slack"
                  />
                </Link>
                {/* <a
                  className="social-link ml-3 w-[48px] h-[48px] rounded-full shadow-md inline-flex justify-center items-center hover:text-[#247EFE]"
                  href="https://www.linkedin.com"
                >
                  <FaGithub />
                </a> */}
              </div>
            </div>
            <div className="flex flex-col md:flex-row lg:w-2/3 md:justify-between justify-start md:gap-0 gap-7 md:my-0 my-10 items-start">
              <div className="lg:w-1/2">
                <p className="text-3xl font-[500] text-[#082247] md:text-[#8390A3]">
                  Links
                </p>
                <ul className="mt-5">
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="/">DataMates</Link>
                  </li>
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="/analytics">Analytics Engineering</Link>
                  </li>
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="/platform">Platform Engineering</Link>
                  </li>
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="/pricing">Pricing</Link>
                  </li>
                </ul>
              </div>
              <div className="lg:w-1/2">
                <p className="text-3xl font-[500] text-[#082247] md:text-[#8390A3]">
                  Resources
                </p>
                <ul className="mt-5">
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="/videos">Tutorials</Link>
                  </li>
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="https://docs.altimate.ai">Docs</Link>
                  </li>
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="/security">Security</Link>
                  </li>
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="/about">About us</Link>
                  </li>
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="/support">Support</Link>
                  </li>
                  <li className="mb-2 text-xl hover:text-[#247EFE]">
                    <Link to="https://blog.altimate.ai">Blog</Link>
                  </li>

                  {/* <li className="mb-3 hover:text-[#247EFE]">
                    <Link to="/career">Career</Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="lg:w-4/12 newsletter bg-[#F5F7F9] p-6 md:p-0 md:bg-white">
            <p className="text-xl font-[500] text-[#082247] md:text-[#8390A3]">
              Newsletter
            </p>
            <p className="mt-5 text-[#5E7493] text-[16px] font-[400]">
              Sign up for our newsletter to get latest info on features and
              releases
            </p>

            <div id="mc_embed_signup">
              <NewsLetterForm />
            </div>
          </div>
        </div>

        <hr />

        <div className="flex flex-col text-center md:flex-row mb-0 md:mb-0 justify-between items-top mt-8">
          <div>
            <p className="text-[#082247] text-[16px] font-normal md:text-left text-center md:mb-0 mb-[16px]">
              All rights reserved @altimate.ai
            </p>
            <p className="mt-2 text-xs text-[#5E7493] md:text-[#365178]">
              {DBT_NAME} and snowflake are registered trademarks of respective
              companies
            </p>
          </div>
          <div className="flex mt-6 md:mt-0 flex-col md:flex-row lg:justify-end justify-between f-links">
            <a href="/privacy" className="text-[#365178]" target="_blank">
              Privacy Policy
            </a>
            <a href="/terms" className="text-[#365178] md:ml-6" target="_blank">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
