import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/footer";

import scrollTop from "../assets/images/svgs/scrollTop.svg";

const Main = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const scrollToTop = () => {
    // scroll animation
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <Outlet />
      <Footer />

      {/* scroll to top */}
      <div className="fixed bottom-4 md:bottom-2 right-5 z-50 cursor-pointer">
        <button onClick={scrollToTop}>
          <img src={scrollTop} alt="scroll to top" />
        </button>
      </div>
    </div>
  );
};

export default Main;
