import tutorial_1 from "../../assets/images/jpgs/tutorial_1.jpeg";
import tutorial_2 from "../../assets/images/jpgs/tutorial_2.jpeg";
import tutorial_3 from "../../assets/images/jpgs/tutorial_3.jpeg";
import tutorial_4 from "../../assets/images/jpgs/tutorial_4.jpeg";
import tutorial_5 from "../../assets/images/svgs/tutorials/tutorial_5.svg";
import tutorial_6 from "../../assets/images/svgs/tutorials/tutorial_6.svg";
import tutorial_7 from "../../assets/images/svgs/tutorials/tutorial_7.svg";
import tutorial_8 from "../../assets/images/svgs/tutorials/tutorial_8.svg";
import tutorial_9 from "../../assets/images/svgs/tutorials/tutorial_9.svg";
import tutorial_10 from "../../assets/images/svgs/tutorials/tutorial_10.svg";
// import tutorial_11 from "../../assets/images/svgs/tutorials/tutorial_11.svg";
import tutorial_12 from "../../assets/images/svgs/tutorials/tutorial_12.svg";
import tutorial_13 from "../../assets/images/svgs/tutorials/tutorial_13.svg";
import tutorial_14 from "../../assets/images/svgs/tutorials/tutorial_14.svg";
import { DBT_NAME } from "../../constant";

export const tutorialData = [
  {
    title: <>Understand {DBT_NAME} model and update it with natural language</>,
    description: (
      <>
        In this video, Patricia will take you on a journey to understand code
        and business logic of existing {DBT_NAME} model, and make the changes to{" "}
        {DBT_NAME} model as per business requirements. Here are steps that she
        has covered: understand the {DBT_NAME} model code better, generate code
        for {DBT_NAME} model updates, make adjustments to the code as needed
      </>
    ),
    tags: ["development", "query explanation", "dbt model update", "gen AI"],
    youtubeLink: "https://youtu.be/D2I_1MzdZL8",
    thumbnail: <img src={tutorial_1} alt="tutorial_1" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/D2I_1MzdZL8"
        title={`Understand ${DBT_NAME} model and update it with natural language`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: <>Create a data mart in {DBT_NAME} from scratch</>,
    description: (
      <>
        Many times analytics engineers need to quickly put data marts together.
        Patricia will walk us through how easy it is to get the basic data mart
        going. Following are some interesting tips that she has covered in the
        video: generate staging model code from source file, auto-complete
        referenced {DBT_NAME} model names, and generate descriptions for $
        {DBT_NAME} models / columns.
      </>
    ),
    tags: [
      "development",
      "dbt model generation",
      "autocompletedbtcode",
      "documentation",
      "gen AI",
    ],
    youtubeLink: "https://youtu.be/v78f9acZ5l0",
    thumbnail: <img src={tutorial_2} alt="tutorial_2" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/jeluUHOsces"
        title={`Create a data mart in ${DBT_NAME} from scratch`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "dbt model debug - Model validation and query analysis",
    description: (
      <>
        In our world of data development, many times more efforts and time are
        spent in debugging existing code than actual writing new code - and $
        {DBT_NAME} code is not an exception. In this video, Patricia will cover
        how to debug {DBT_NAME} model for SQL related errors like non-existant
        columns and preview data with some charting to make sure right results
        are produced by the {DBT_NAME} model.
      </>
    ),
    tags: ["testing", "preview query results", "sql validator"],
    youtubeLink: "https://youtu.be/NFZG8ZJr5OM",
    thumbnail: <img src={tutorial_3} alt="tutorial_3" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/RZHpEN5g460"
        title="dbt model debug - Model validation and query analysis"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "dbt model debug - Column lineage and Project Health Check",
    description: (
      <>
        In this video, join Patricia on our ongoing journey into the world of
        data model debugging. Learn how to effectively analyze the impact of
        changes in your downstream models using column lineage. Discover
        essential techniques for conducting sanity checks on your {DBT_NAME}{" "}
        model, ensuring all columns are accounted for, and verifying
        documentation completeness.
      </>
    ),
    tags: [
      "testing",
      "column lineage",
      "project health check",
      "documentation",
    ],
    youtubeLink: "https://youtu.be/ahsQiIJGekU",
    thumbnail: <img src={tutorial_4} alt="tutorial_4" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/ahsQiIJGekU"
        title="dbt model debug - Column lineage and Project Health Check"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },

  {
    title: "defer running upstream models in development",
    description: (
      <>
        Defer is the functionality that’s underutilized by many data teams when
        it comes to {DBT_NAME} development. Defer functionality in {DBT_NAME}{" "}
        allows the user to run a subset of models or tests without having to
        first build their upstream parents (more info) - leading to significant
        time and cost savings. In this tutorial, learn how to enable it and use
        it during the {DBT_NAME} development.
      </>
    ),
    tags: ["testing", "development", "collaboration", "defer-to-prod"],
    youtubeLink: "https://youtu.be/lBklxVaRRi0",
    thumbnail: <img src={tutorial_5} alt="tutorial_5" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/lBklxVaRRi0"
        title="defer running upstream models in development"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },

  {
    title: "Don’t write, but generate data quality tests!",
    description: (
      <>
        Writing tests is an additional chore that many of us would like to wrap
        quickly, but it’s an essential step before the {DBT_NAME} model is used
        to make sure data is being transformed accurately and consistently.
        Patricia will walk us through how to generate default and custom $
        {DBT_NAME} tests (from packages, macros) as well as update or delete
        existing tests.
      </>
    ),
    tags: ["testing", "gen AI"],
    youtubeLink: "https://youtu.be/0SjPa_joSns",
    thumbnail: <img src={tutorial_6} alt="tutorial_6" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/0SjPa_joSns"
        title="Don’t write, but generate data quality tests!"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "dbt Model Contracts",
    description: (
      <>
        Join Patricia as she shares her experience of a classic data management
        challenge—unexpected changes in source data that disrupted her
        downstream {DBT_NAME} models. In this insightful video, she details how
        this issue prompted her to implement {DBT_NAME} contracts to establish
        strict data type guardrails. Plus, don’t miss her expert tip on using
        Power User for {DBT_NAME} to quickly populate data types in YAML files,
        enabling rapid setup of data contracts.
      </>
    ),
    tags: ["testing", "development"],
    youtubeLink: "https://youtu.be/FPgbcrHMlt4",
    thumbnail: <img src={tutorial_7} alt="tutorial_1" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/FPgbcrHMlt4"
        title="dbt Model Contracts"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "Code and docs collaboration",
    description: (
      <>
        When you're crafting {DBT_NAME} code or documentation, the need to
        quickly consult with stakeholders for additional information or reviews
        is common. Currently, this process is disjointed, spread across multiple
        tools. Not anymore. In this video, Patricia introduces DataPilot's
        remarkable collaboration features, seamlessly integrating IDE and SaaS
        UI to enhance interactions between technical and non-technical
        stakeholders
      </>
    ),
    tags: ["collaboration", "development", "documentation"],
    youtubeLink: "https://youtu.be/u-bOJ8Tztls",
    thumbnail: <img src={tutorial_8} alt="tutorial_8" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/u-bOJ8Tztls?si=wNSCz7VNe4GLKYRp"
        title="Code and docs collaboration"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: <>Follow best practices in {DBT_NAME} development</>,
    description: (
      <>
        Are you a developer who often gets caught up in the excitement of
        reaching the finish line, sometimes at the expense of best practices? As
        projects grow, neglecting these best practices can lead to issues down
        the road. In this insightful video, Patricia guides us through how to
        easily check for best practices in your {DBT_NAME} projects and how to
        make necessary corrections along the way.
      </>
    ),
    tags: ["collaboration", "testing", "development", "governance"],
    youtubeLink: "https://youtu.be/RMrKCSaU3_A",
    thumbnail: <img src={tutorial_9} alt="tutorial_9" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/RMrKCSaU3_A?si=yUelupYwT9GMWgRv"
        title={`Follow best practices in ${DBT_NAME} development`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: <>DataPilot CLI usage for {DBT_NAME} Project Governance</>,
    description: (
      <>
        This video is a follow-up to the previous video titled “Follow best
        practices in {DBT_NAME} Development” where Patricia dives deeper into
        configuring governance checks using YAML file configuration. She also
        demonstrates how to utilize the DataPilot CLI and the Power User VSCode
        extension so that these checks can be performed in various stages of
        development.
      </>
    ),
    tags: ["testing", "development"],
    youtubeLink: "https://youtu.be/QT0N6svh-5o",
    thumbnail: <img src={tutorial_10} alt="tutorial_10" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/QT0N6svh-5o?si=QwL1_hItE3eg1QLN"
        title={`DataPilot CLI usage for ${DBT_NAME} Project Governance`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title:
      "Trace impact of code changes in column lineage with code visibility",
    description:
      "Join Patricia as she demonstrates how to prevent your code changes from disrupting downstream models. She will guide you through tracing specific column changes in downstream models and show you how to easily access the exact code snippets responsible for these transformations using the column lineage view.",
    tags: ["testing", "column lineage", "dbt model update"],
    youtubeLink: "https://youtu.be/PcFoPFKhH3I",
    thumbnail: <img src={tutorial_12} alt="tutorial_12" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/PcFoPFKhH3I?si=QwL1_hItE3eg1QLN"
        title="Trace impact of code changes in column lineage with code visibility"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "Graphically visualize your SQL for refactoring or debugging",
    description: (
      <>
        As engineers, a significant portion of our time is put into debugging
        and enhancing existing code. In this video, Patricia demonstrates how to
        get visual representation your {DBT_NAME} or SQL code, providing a clear
        view of its structure and the connections between different components.
        She also showcases how this info can be easily shared via UI with fellow
        developers and QA engineers.
      </>
    ),
    tags: ["collaboration", "testing", "development", "sql validator"],
    youtubeLink: "https://youtu.be/0wfJJ4OVDEA",
    thumbnail: <img src={tutorial_13} alt="tutorial_13" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/0wfJJ4OVDEA?si=UJBtOLURfbQjc-w2"
        title="Graphically visualize your SQL for refactoring or debugging"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "Bookmark your favorite queries and share with others",
    description: (
      <>
        Master the art of data validation for {DBT_NAME} models with Patricia's
        expert guidance. Learn to craft ad-hoc queries that verify your{" "}
        {DBT_NAME} model outputs and track changes during development. This
        video also covers efficient query history management, including
        bookmarking queries and sharing those bookmarks with your team,
        enhancing your collaborative data analytics workflow
      </>
    ),
    tags: ["collaboration", "testing", "bookmark", "share queries"],
    youtubeLink: "https://youtu.be/VzXnxl77Lq0",
    thumbnail: <img src={tutorial_14} alt="tutorial_14" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/VzXnxl77Lq0?si=UJBtOLURfbQjc-w2"
        title="Bookmark your favorite queries and share with others"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
];
