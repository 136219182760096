import React from "react";

// import partner1 from "../../assets/images/svgs/partner1.svg";
// import partner2 from "../../assets/images/svgs/partner2.svg";
// import partner3 from "../../assets/images/svgs/partner3.svg";
// import partner4 from "../../assets/images/svgs/partner4.svg";

import human0 from "../../assets/images/pngs/h0.png";
import human1 from "../../assets/images/pngs/h1.png";
import human2 from "../../assets/images/pngs/h2.png";
import human3 from "../../assets/images/pngs/h3.png";
import { FaLinkedinIn } from "react-icons/fa";

// const partners = [partner1, partner2, partner3, partner4];

const humanPartners = [
  {
    id: 0,
    img: human0,
    name: "Michel Tricot",
    designation: "CEO & Co-Founder at Airbyte",
    linkedin: "https://www.linkedin.com/in/micheltricot/",
  },
  {
    id: 1,
    img: human1,
    name: "Shashank Tiwari",
    designation: "Entrepreneur, CEO, Executive, Engineer, Mathematician",
    linkedin: "https://www.linkedin.com/in/tshanky/",
  },
  {
    id: 2,
    img: human2,
    name: "Pradeep Padala",
    designation: "Entrepreneur and Investor",
    linkedin: "https://www.linkedin.com/in/ppadala/",
  },
  {
    id: 3,
    img: human3,
    name: "Ashish Agrawal",
    designation: "Entrepreneur and Investor",
    linkedin: "https://www.linkedin.com/in/agrawalashishkumar",
  },
];

const Partners = () => {
  return (
    <div className="partners bg-white pt-[80px] pb-[60px]">
      <div className="custom-container">
        <h2 className="text-[#082247] md:text-[42px] text-[24px] font-[600] text-center lh-2">
          Altimate AI is backed by world-
          <br className="md:block hidden" />
          class investors and advisors
        </h2>

        {/* <div className="partners grid lg:grid-cols-4 grid-cols-2 gap-8 mt-[60px]">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="partner-card flex items-center justify-center"
            >
              <img src={partner} alt="" />
            </div>
          ))}
        </div> */}

        <div className="human-partners mt-[60px] flex lg:flex-row flex-col lg:justify-center justify-between items-start">
          {humanPartners.map((human, index) => (
            <div
              key={index}
              className="human-card flex items-center justify-center flex-col lg:w-1/4 w-1/2"
            >
              <div className="human-img">
                <img src={human.img} alt="" />
              </div>
              <div className="human-info text-center mt-[20px]">
                <h3 className="font-[600] text-[#8390A3]">{human.name}</h3>
                <p className="text-[#082247] text-sm h-[40px] mb-3">
                  {human.designation}
                </p>
                <a
                  href={human.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex w-[24px] h-[24px] text-xs justify-center items-center bg-[#247EFE] rounded-full text-white"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
