import { useState } from "react";
import { VSCODE_MARKETPLACE_API_URL } from "../../config";
import dropDownArrow from "../../assets/images/svgs/dropdown_arrow.svg";

export const vsCodeMarketplaceURL = VSCODE_MARKETPLACE_API_URL;
export const dbtProductName = "innoverio.vscode-dbt-power-user";
export const dbtExtensionName = "vscode-dbt-power-user";
export const dbtProductDisplayName = "Power User for dbt Core™";
export const defaultDownloadsCount = 160;

export const formatNumber = (num) => num.toLocaleString();
export const formatNumberK = (num) => Math.floor(num / 1000);

export const processVSCodeDownloadsCount = (data) => {
  if (data) {
    if (!data || !data.results) {
      console.error("No results in response");
      return defaultDownloadsCount * 1000;
    }
    const powerUserExt = data.results
      .flatMap((item) => item.extensions)
      .find((subItem) => subItem.extensionName === dbtExtensionName);
    if (!powerUserExt) {
      console.error("No matching displayName found");
      return defaultDownloadsCount * 1000;
    }
    const installs = powerUserExt.statistics.find(
      (item) => item.statisticName === "install",
    );
    if (!installs) {
      console.error("No 'install' statisticName found");
      return defaultDownloadsCount * 1000;
    }
    return installs.value === 0 ? defaultDownloadsCount * 1000 : installs.value;
  }
};

export const CustomDropdown = ({ value, options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="w-full px-[2.5%] relative">
      <div
        className="w-full h-12 bg-slate-50 rounded-lg border border-slate-400/opacity-50 flex inline-flex justify-between items-center px-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-sky-950 text-lg font-medium font-['Lexend']">
          {value}
        </div>
        <img src={dropDownArrow} alt="drop-down-arrow" />
      </div>

      {isOpen && (
        <div className="w-full pr-[5%] bg-white rounded shadow border-0 absolute z-50">
          {options.map((option, index) => (
            <>
              <div
                key={option}
                className="w-full h-12 flex justify-start items-center px-4 text-sky-950 text-lg font-medium font-['Lexend'] cursor-pointer hover:bg-[#f5f8ff]"
                onClick={() => handleSelect(option)}
              >
                {option}
              </div>
              {index !== options.length - 1 && (
                <div className="w-full h-[0px] border border-slate-400/opacity-20"></div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
